import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, SideBar } from 'components';
import { UiHomeGroupLayout, UiHomeGroupContent, UiChildrenContainer } from './HomeGroupLayout.style';


/**
 * 前台頁面組件 沒有SideBar 用於條款頁
 */

export const HomeGroupLayout = ({ children, showSideBar = true }) => {
  return (
    <UiHomeGroupLayout>
      {/* <HomeBanner showSideBar={showSideBar} /> */}
      {
        showSideBar &&
        <SideBar />
      }
      <UiHomeGroupContent showSideBar={showSideBar ? true : false}>
        <Header isNeedLogo={false} />
        <UiChildrenContainer>
          {children}
          <Footer />
        </UiChildrenContainer>
      </UiHomeGroupContent>
    </UiHomeGroupLayout>
  );
};

HomeGroupLayout.propTypes = {
  children: PropTypes.node,
  showSideBar: PropTypes.bool
};


