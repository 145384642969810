import { Api } from 'services/api';
import { stringify } from 'query-string';
/**
 * 取得組織邀請列表
 */
export const getOrganizationInvitations = async (payload) => {
  const querystring = stringify(payload);
  const res = await Api.get(`/users/organization-invitations?${querystring}`);
  return res;
};

/**
 * 取得非組織邀請列表
 */
export const getGroupInvitations = async (payload) => {
  const querystring = stringify(payload);
  const res = await Api.get(`/users/group-invitations?${querystring}`);
  return res;
};

/**
 * 接受組織邀請
 * @param {string} organizationId
 */
export const acceptOrganizationInvitation = async organizationId => {
  const { isSuccess,error } = await Api.post(`/users/organization-invitations/${organizationId}/accept`);
  return {
    isSuccess,
    error
  };
};

/**
 * 接受非組織邀請
 * @param {string} classId
 */
export const acceptGroupInvitation = async classId => {
  const { isSuccess,error } = await Api.post(`/users/group-invitations/${classId}/accept`);
  return {
    isSuccess,
    error
  };
};

/**
 * 取得已送出未接受非組織邀請的人員列表
 * @param {string} classId
 */
export const getFreeGroupUserInvitations = classId => async params => {
  const { role, nowPage, rowsPage } = params;

  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (role) {
    queryParams.role = role;
  }
  const querystring = await stringify(queryParams);

  const response = await Api.get(`/groups/${classId}/users/invitations?${querystring}`);
  return response;
};

export const cancelFreeGroupUserInvitation = classId => async (userId) => {
  const response = await Api.delete(`/groups/${classId}/users/invitations/${userId}`);
  return response;
};

//重新拉取oneCLass資料
export const refreshProfile = async () => {
  const response = await Api.get('/users/me/refresh');
  return response;
};



//更新會員資料
export const updateMyProfile = async (params) => {
  const {
    nickname,
    name,
    mobileNumber,
    phoneNumber,
    gender,
    birthday,
    address,
    addressData,
    thumbnailUrl
  } = params;
  const queryParams = {};
  if (nickname) queryParams.nickname = nickname;
  if (name) {
    if (name.firstName)
      queryParams.firstName = name.firstName;
    if (name.lastName)
      queryParams.lastName = name.lastName;
  }
  if (mobileNumber) queryParams.mobileNumber = mobileNumber;
  if (phoneNumber) queryParams.phoneNumber = phoneNumber;
  if (gender) queryParams.gender = gender;
  if (birthday) queryParams.birthday = birthday;
  if (address) queryParams.address = address;
  if (addressData) {
    if (addressData.county)
      queryParams.addressCountyName = addressData.county;
    if (addressData.township)
      queryParams.addressDistrictName = addressData.township;
  }
  if (thumbnailUrl) queryParams.thumbnailUrl = thumbnailUrl;
  const response = await Api.put('/users/me', queryParams);
  return response;
};


/**
 * 申請專業版會員
 * @param {*} params
 */
export const setPremiumUsers = async (params) => {
  const { mobileNumber, email, userId } = params;
  const queryParams = {};

  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }

  if (email) {
    queryParams.email = email;
  }
  if (userId) {
    queryParams.userId = userId;
  }
  const response = await Api.post('/users/premium', queryParams);
  return response;
};


/**
 * 取得私人教材列表
 * @param {*} params
 */
 export const getPrivateResources = async (params) => {
  const { nowPage = 0, rowsPage, keyword } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if(keyword) {
    queryParams.keyword = keyword;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(`/users/resources?${querystring}`);
  return response;
};

/**
 * 取得教材
 * @param {*} params
 */
 export const getOrder = async (code) => {
  const response = await Api.post(`/users/resources/orders/${code}/redeem`);
  return response;
};


/**
 * 取得私人卷類列表
 * @param {*} params
 */
 export const getPrivateQuizzes = async (params) => {
  const { nowPage = 0, rowsPage,subjectCode,productCode } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if(subjectCode) {
    queryParams.subjectCode = subjectCode;
  }
  if(productCode) {
    queryParams.productCode = productCode;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(`/users/quizzes?${querystring}`);
  return response;
};

/**
 * 獲取卷類選項
 */

 export const getQuizzesOptions = async () => {
  const response = await Api.get('/users/quizzes/options');
  return response;
};

/**
 * 取得私人卷類列表
 *
 * @param {string} contactMobileNumber 業務手機
 * @param {string} userName 老師姓名
 * @param {string} userMobileNumber 老師手機
 * @param {string} educationName 學制
 * @param {string} schoolCityName 學校 縣市
 * @param {string} schoolDistrictName 學校 鄉鎮市區
 * @param {string} schoolId 學校
 */
export const getTeacherVerification = async params => {
  const response = await Api.post('/users/teacher-verifications', params);
  return response;
};
