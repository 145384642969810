import React, { useEffect, useMemo } from 'react';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { stringify } from 'query-string';
import { paginationFilter } from  'utils/pagination';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useTeacher } from 'store/teacher';
import { useSetState } from 'utils/hooks/useSetState';
import { Table, Button, Loading, Select } from 'components';
import { Tab, Box } from '@material-ui/core';
import {
  getTeacherAssignedVideoRecord,
  getStudentVideoRecord,
  getStudentAllVideoRecord
 } from 'services/api/home/video';
import { UiHomeStudentVideoTable,UiMatTabs } from './HomeStudentVideoTable.style';


/**
 * 學生影音列表
 */

 const ViewedTable = ({
   videoRecord,
   teacherId,
   classId,
   studentId
  }) => {

  const [
    {
      nowPage,
      rowsPage,
    }, setState] = useSetState({
      nowPage: 0,
      rowsPage: 50,
    });


  const schema = {
    item:{
      name: '品項',
      defaultValue: '-'
    },
    name: {
      name: '影片名稱',
      defaultValue: '-'
    },
    time:{
      name:'時間',
      defaultValue:'--'
    },
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const ActionComponents = ({ params }) => {
    const goToVideoBank = () => {
      const queryString = stringify({
        teacher: teacherId,
        course: classId,
        student: studentId,
        no: params.no
      });
      window.open(
        `${process.env.REACT_APP_VIDEO_BANK_DOMAIN}?${queryString}`
      );
    };
    return (
      <Button buttonColor="new" onClick={goToVideoBank}>影片播放</Button>
    );
  };


  return (
    <Table
      data={paginationFilter(videoRecord,nowPage,rowsPage)}
      schema={schema}
      changePage_Rows={changePage_Rows}
      totalPage={videoRecord.length || 10}
      ActionComponents={ActionComponents}
      nowPage={nowPage}
    />
  );
};

 const AssignedTable = ({ videoRecord, classId, teacherId, studentId }) => {
  const [
    {
      data,
      nowPage,
      rowsPage,
    }, setState] = useSetState({
      data: [],
      nowPage: 0,
      rowsPage: 10,
    });

  const schema = {
    item:{
      name: '品項',
      defaultValue: '-'
    },
    name:{
      name:'影片名稱',
      defaultValue:'--'
    },
    time: {
      name: '時間',
      defaultValue: '00:00'
    },
    isViewed: {
      name: '狀態',
      default: '未觀看'
    }
  };


  const isViewedShowText = [
    {
      id: 'isViewed',
      state: {
        false: '未觀看',
        true: '觀看完成',
      }
    }
  ];


  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const ActionComponents = ({ params }) => {
    const goToVideoBank = () => {
      const queryString = stringify({
        teacher: teacherId,
        course: classId,
        student: studentId,
        no: params.no
      });
      window.open(
        `${process.env.REACT_APP_VIDEO_BANK_DOMAIN}?${queryString}`
      );
    };
    return (
      <Button buttonColor="new" onClick={goToVideoBank}>影片播放</Button>
    );
  };

  const fetchAssignVideoList = async ({ classId, teacherId }) => {
    const res = await getTeacherAssignedVideoRecord({ classId, teacherId });
    if(res.isSuccess) {
      setState({ data: Object.values(res.data) });
    }
  };

  useEffect(() => {
    fetchAssignVideoList({ classId, teacherId });
  },[classId,teacherId]);


  const videoList = useMemo(() => {
    const viewedVideoIds = videoRecord.map(item => item.no);
    return data.map(item => ({...item, isViewed: viewedVideoIds.includes(item.no) }));
  },[data]);

  return (
      <Table
        data={paginationFilter(videoList,nowPage,rowsPage)}
        schema={schema}
        stateShowText={isViewedShowText}
        changePage_Rows={changePage_Rows}
        totalPage={data.length}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
  );
};


export const HomeStudentVideoTable = () => {
  const isVisible = usePageVisibility();
  const [{ profile }] = useUser();
  const { id: userId } = profile;
  const [{ myClasses: { dataInfo: { id: classId } } }] = useClass();
  const [{ teachers: { data } }, { getTeachers }] = useTeacher();


  const [
    {
      tabIndex,
      teacherId,
      recordTeacherId,
      videoRecord
   }, setState] = useSetState({ tabIndex: 0, teacherId: '',recordTeacherId:'all', videoRecord:[] });

  const getTabIndexHandler = (e,tabIndex) => {
    setState({
      tabIndex
    });
  };


  const fetchStudentVideoRecord = async () => {
    const payload = {
      classId,
      studentId: userId,
      teacherId : recordTeacherId
    };
    if(recordTeacherId === 'all') delete payload.teacherId;
    const res = recordTeacherId === 'all' ?
      await getStudentAllVideoRecord(payload) :
      await getStudentVideoRecord(payload);
    if(res.isSuccess){
      const nextData = res.data.sort((a,b)=> b.addAt - a.addAt);
      setState({ videoRecord: nextData });
    }
  };

  useEffect(() => {
    if(!recordTeacherId) return;
    fetchStudentVideoRecord();
  },[recordTeacherId,isVisible]);

  useEffect(() => {
    getTeachers({
      nowPage: 0,
      rowsPage: 100
    });
  },[]);


  const teachers = useMemo(()=>{
    return data.map(teacher => ({ name: teacher.nickname, value: teacher.id  })) || [];
  },[data]);

  useEffect(() => {
    if(data.length > 0) {
      setState({ teacherId: data[0].id });
    }
  },[data]);

  const goVideoBK = () => {
    const queryString = stringify({
      course: classId,
      student: profile.id,
    });
    window.open(
      `${process.env.REACT_APP_VIDEO_BANK_DOMAIN}?${queryString}`
    );
  };


  return (
    <UiHomeStudentVideoTable>
      <h4>影音學習</h4>
      <UiMatTabs
        value={tabIndex}
        onChange={getTabIndexHandler}
      >
        <Tab label="老師指派影片" />
        <Tab label="學生觀看紀錄" />
      </UiMatTabs>
      <Box width="100%" mb={2} display="flex" alignItems="center" justifyContent="space-between">
        {
          tabIndex === 0 ?
        <Select
          name='teacher'
          label='老師'
          value={teacherId}
          options={teachers}
          onChangeHandler={(teacherId) => setState({ teacherId })}
        /> :
        <Select
        name='teacher'
        label='老師'
        value={recordTeacherId}
        options={[{name:'全部',value:'all'}].concat(teachers)}
        onChangeHandler={(teacherId) => setState({ recordTeacherId:teacherId })}
      />
        }
        <Button onClick={()=>goVideoBK()}>前往南一影音網</Button>
      </Box>
      {
        teacherId ? <>
           {
              tabIndex === 0 && (
                <AssignedTable videoRecord={videoRecord} classId={classId} studentId={userId} teacherId={teacherId} />
              )
            }
            {
              tabIndex === 1 && (
                <ViewedTable
                  videoRecord={videoRecord}
                  classId={classId}
                  studentId={userId}
                  teacherId={recordTeacherId}
                />
              )
            }
        </> : <Loading />
      }

    </UiHomeStudentVideoTable>
  );
};
