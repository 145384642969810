import { ReactComponent as Exam } from './exam.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as Film } from './film.svg';
import { ReactComponent as GroupList } from './groupList.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as Member } from './member.svg';
import { ReactComponent as Oms } from './oms.svg';
import { ReactComponent as Picture } from './picture.svg';
import { ReactComponent as Thumbs } from './thumbs.svg';
import { ReactComponent as Video } from './video.svg';
import { ReactComponent as Album } from './album_solid.svg';
import { ReactComponent as BackToList } from './back_to_list_solid.svg';
import { ReactComponent as BookReader } from './book_reader_solid.svg';
import { ReactComponent as Bullhorn } from './bullhorn_solid.svg';
import { ReactComponent as Cog } from './cog_solid.svg';
import { ReactComponent as CommentAlt } from './comment_alt_solid.svg';
import { ReactComponent as Comments } from './comments_solid.svg';
import { ReactComponent as EnvelopeOpenText } from './envelope_open_text_solid.svg';
import { ReactComponent as Homework } from './homework_solid.svg';
import { ReactComponent as Introduction } from './introduction_solid.svg';
import { ReactComponent as Users } from './users_solid.svg';
import { ReactComponent as Stopwatch } from './stopwatch_solid.svg';
import { ReactComponent as AddressCard } from './address_card_solid.svg';
import { ReactComponent as UserLock } from './user_lock_solid.svg';
import { ReactComponent as Line } from './line.svg';
import { ReactComponent as School } from './school.svg';
import { ReactComponent as Invite } from './invite.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Thumbtack } from './thumbtack.svg';
import { ReactComponent as Thumbtacked } from './thumbtacked.svg';
import { ReactComponent as Shapes } from './shapes.svg';
import { ReactComponent as Attachment } from './attachment.svg';
import { ReactComponent as Reminder } from './reminder.svg';
import { ReactComponent as Lightbulb } from './lightbulb.svg';
import { ReactComponent as Community } from './community.svg';
import { ReactComponent as ChevronRight } from './chevron_right_outline.svg';
import { ReactComponent as Comment } from './comment.svg';
import { ReactComponent as HeartOff } from './heart_off.svg';
import { ReactComponent as HeartOn } from './heart_on.svg';
import { ReactComponent as Pdf } from './file_pdf_solid.svg';
import { ReactComponent as Plane } from './paper_plane_solid.svg';
import { ReactComponent as Gift } from './gift_solid.svg';
import { ReactComponent as Edit } from './edit_solid.svg';
import { ReactComponent as Vector } from './Vector.svg';
import { ReactComponent as AirPlane } from './plane.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Notice } from './bell_outline.svg';
import { ReactComponent as ChevronUp } from './chevron_up_outline.svg';
import { ReactComponent as Calendar } from './calendar_alt_solid.svg';
import { ReactComponent as Rocket } from './rocket_solid.svg';
import { ReactComponent as BookOpen } from './book_open_solid.svg';
import { ReactComponent as Clock } from './clock_solid.svg';
import { ReactComponent as DoorIn } from './door_in_solid.svg';
import { ReactComponent as DoorOut } from './door_out_solid.svg';
import { ReactComponent as Hourglass } from './hourglass_half_outline.svg';
import { ReactComponent as FullVideo } from './video_solid.svg';
import { ReactComponent as Trophy } from './trophy_solid.svg';
import { ReactComponent as Running } from './running_solid.svg';
import { ReactComponent as Raisehand } from './raisehand_solid.svg';
import { ReactComponent as MessageWaveform } from './message.and.waveform.fill.svg';
import { ReactComponent as Zoom } from './zoom_solid.svg';
import { ReactComponent as Visibility } from './visibility.svg';
import { ReactComponent as EditPen } from './edit.svg';

export default {
  Exam,
  File,
  Film,
  GroupList,
  Logout,
  Member,
  Oms,
  Picture,
  Thumbs,
  Video,
  Album,
  BackToList,
  BookReader,
  Bullhorn,
  Cog,
  CommentAlt,
  Comments,
  EnvelopeOpenText,
  Homework,
  Introduction,
  Users,
  Stopwatch,
  AddressCard,
  UserLock,
  Line,
  School,
  Invite,
  Copy,
  Thumbtack,
  Thumbtacked,
  Shapes,
  Attachment,
  Reminder,
  Lightbulb,
  Community,
  ChevronRight,
  Comment,
  HeartOff,
  HeartOn,
  Pdf,
  Plane,
  Gift,
  Edit,
  Vector,
  AirPlane,
  Delete,
  Notice,
  ChevronUp,
  Calendar,
  Rocket,
  BookOpen,
  Clock,
  DoorIn,
  DoorOut,
  Hourglass,
  FullVideo,
  Trophy,
  Running,
  Raisehand,
  MessageWaveform,
  Zoom,
  Visibility,
  EditPen,
};
