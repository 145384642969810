import React, { useEffect } from 'react';

import { SessionCard } from 'components';
import { useSessions } from 'store/sessions';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { useDateInterval } from 'utils/hooks/useDateInterval';
import { stringify } from 'query-string';
import { Button, Loading, Checkbox, Icon } from 'components';

import {
  UiMySessions,
  UiSessionTab,
  UiSessionTabItem,
  UiTrack,
  UiButtonBox,
  UiCenterBox,
  UiMainContent,
} from './MySessions.style';


/**
 * 所有課程
 */

const noSessionsPrompt = {
  today: {
    host: '您今天沒有安排授課課程喔！',
    all: '您今天沒有安排任何課程喔！'
  },
  future: {
    host: '您明天起沒有安排授課課程喔！',
    all: '您明天起沒有安排任何課程喔！'
  },
  past: {
    host: '您目前還沒有教授過課程喔！',
    all: '您目前還沒有上過任何課程喔!'
  },
  all: {
    host: '您目前沒有授課課程喔！',
    all: '您目前沒有安排任何課程喔！'
  }
};

export const MySessions = () => {
  const [{ mySessions }, { getMySessions }] = useSessions();
  const [{ profile }] = useUser();
  const [, { todayInterval, pastInterval, futureInterval, allInterval }] = useDateInterval();
  const getTodayInterval = todayInterval();
  const [{
    classDateType,
    startAt,
    endAt,
    isLoading,
    isHost
  }, setState] = useSetState({
    classDateType: 'today',
    startAt: getTodayInterval.startAt,
    endAt: getTodayInterval.endAt,
    isLoading: false,
    isHost: false
  });
  const sessionTabs = [
    {
      id: 'today',
      name: '今日課程',
      func: () => {
        const { startAt, endAt } = todayInterval();
        setState({
          classDateType: 'today',
          startAt,
          endAt,
          isHost,
        });
      }
    },
    {
      id: 'future',
      name: '未來課程',
      func: () => {
        const { startAt, endAt } = futureInterval();
        setState({
          classDateType: 'future',
          startAt,
          endAt,
          isHost,
        });
      }
    },
    {
      id: 'past',
      name: '已上課程',
      func: () => {
        const { startAt, endAt } = pastInterval();
        setState({
          classDateType: 'past',
          startAt,
          endAt,
          isHost,
        });
      }
    },
    {
      id: 'all',
      name: '全部課程',
      func: () => {
        const { startAt, endAt } = allInterval();
        setState({
          classDateType: 'all',
          startAt,
          endAt,
          isHost,
        });
      }
    },
  ];

  const getMySessionsHandler = async params => {
    setState({
      isLoading: true
    });
    await getMySessions(params);
    setState({
      isLoading: false
    });
  };

  const checkHandler = value => {
    setState({
      isHost: value === 'isHost' ? true : false
    });
  };

  const enterClass = session => {
    const { id, resourceId, classType,hostId, type,users } = session;
    if (classType !== 'class') return;
    const enterInteractiveSession = () => {
      const usersLength = users?.length || 0;
      let classType = '';
      switch(type){
        case 'interactive':
          classType = usersLength <= 1 ? 'single' : 'group';
          break;
        case 'collaborative':
          classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
          break;
        case 'video':
          classType = 'group';
          break;
        default :
          break;
      }
      const userType = hostId === profile.id ? 'teacher' : 'student';
      const querystring = stringify({
        bookIds: [resourceId],
        classType: classType,
        role: userType,
        userName: profile.nickname,
        userId: profile.id
      },{ arrayFormat: 'index' });
      window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
    };
    if(type === 'interactive' || type === 'video' || type === 'collaborative') {
      enterInteractiveSession();
    }
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceId}`;
    window.open(classRoomUrl, '_blank');
  };

  useEffect(() => {
    const params = {
      nowPage: 0,
      rowsPage: 8,
      startAt,
      endAt,
      isHost
    };
    getMySessionsHandler(params);
  }, [startAt, endAt, isHost]);

  return (
    <>
      <UiTrack>
        <UiSessionTab>
          {
            sessionTabs.map(tab => {
              return (
                <UiSessionTabItem
                  active={classDateType === tab.id ? true : false}
                  key={tab.id}
                  onClick={() => tab.func()}
                >
                  <Icon name="classIcon" haveBg={false} />
                  {tab.name}
                </UiSessionTabItem>
              );
            })
          }
        </UiSessionTab>
        <Checkbox label='只顯示授課課程' checkedValue='isHost' onChange={value => checkHandler(value)} />
      </UiTrack>
      <UiMainContent>
      <UiMySessions>
        {
          isLoading ||
          mySessions.data.map(session => {
            return (
              <SessionCard
                key={session.id}
                groupName={session.groupName}
                sessionName={session.name}
                date={session.startAt}
                coverPath={`https://cdn.oneclass.com.tw/uploadoutput/${session.resourceId}/cover.jpg`}
                time={session.during}
                type={session.classType}
                people={session.users ? session.users.length : 0}
                teacher={session.hostName}
                role={profile.id === session.hostId ? 'teacher' : 'user'}
                onClick={() => enterClass(session)}
              />
            );
          })
        }
        {isLoading && <UiCenterBox><Loading /></UiCenterBox>}
        {
          isLoading ||
          (mySessions.data.length === 0 &&
            <UiCenterBox>
              <span>{noSessionsPrompt[classDateType][isHost ? 'host' : 'all']}</span>
            </UiCenterBox>)
        }
      </UiMySessions>
      {
        isLoading || (mySessions.total === mySessions.data.length ||
          <UiButtonBox>
            <Button onClick={() => getMySessionsHandler(
              {
                nowPage: 0,
                rowsPage: mySessions.total,
                startAt,
                endAt,
                isHost,
              }
            )}>顯示更多</Button>
          </UiButtonBox>)
      }
      </UiMainContent>
    </>
  );
};



