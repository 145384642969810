import styled from 'styled-components';
import {
  TableBody,
  TableCell,
  TableRow,
  Tabs as MatTabs,
 } from '@material-ui/core';

export const UiHomeOnlineExam = styled.div`
	display: block;
	margin-top: 1rem;
`;

export const UiTitle = styled.div`
	font-size: 20px;
	color: #242c3f;
	margin-bottom: 1.5rem;
`;

export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}

	> div > span {
		background-color: #ec7963;
	}
`;

export const UiExamIcons = styled.div`
	> svg {
		font-size: 2rem;
		cursor: pointer;
	}
`;


export const UiTabBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const UiTabItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px 0;
	width: 48%;
	font-size: 16px;
	color: #242c3f;
	border: 1px solid #d5d7de;
	border-radius: 8px;
	line-height: 24px;
	cursor: pointer;
	transition: 0.35s;

	&.active {
		color: #fff;
		background: #121232;
		border: 1px solid #121232;
	}

	& > * + * {
		margin-left: 18px;
	}
`;

export const UiFormBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	padding: 10px 0;
	border: 1px solid #e4e7ec;
	border-radius: 8px;
	flex-wrap: wrap;

	&::after {
		position: absolute;
		top: -8px;
		left: ${({position})=> position};
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #e4e7ec transparent;
		transition: 0.35s;
		content: '';
	}

	&::before {
		position: absolute;
		top: -7px;
		left: ${({position})=> position};
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #fff transparent;
		z-index: 1;
		transition: 0.35s;
		content: '';
	}
`;
