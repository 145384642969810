import React,{ useState , useEffect } from 'react';
import { getMySessions } from 'services/api/home/sessions';
import { DashBoardCard } from 'components';
import { stringify } from 'query-string';
import { useUser } from 'store/user';
import { format } from 'date-fns';
import Book from 'assets/images/defaultBook.png';
import { UiSessionGroup } from './SessionGroup.style';


/**
 * 今日課程
 */

 const todayStartAt = new Date(new Date().toLocaleDateString()).getTime();
 const todayEndAt = todayStartAt + (24 * 60 * 60 * 1000) - 1;
 const nowTime = new Date().getTime();

export const SessionGroup = () => {
  const [sessionsData,setSessionsData] = useState([]);
  const [{ profile }] = useUser();
  const goSessionReader = (item) => {
    const { resourceId, id, hostId, type,users } = item;
    const enterInteractiveSession = () => {
      const usersLength = users?.length || 0;
      let classType = '';
      switch(type){
        case 'interactive':
          classType = usersLength <= 1 ? 'single' : 'group';
          break;
        case 'collaborative':
          classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
          break;
        case 'video':
          classType = 'group';
          break;
        default :
          break;
      }
      const userType = hostId === profile.id ? 'teacher' : 'student';
      const querystring = stringify({
        bookIds: [resourceId],
        classType: classType,
        role: userType,
        userName: profile.nickname,
        userId: profile.id
      },{ arrayFormat: 'index' });
      window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
    };
    if(type === 'interactive' || type === 'video' || type === 'collaborative') {
      enterInteractiveSession();
    }else{
      const classRoomUrl =
      `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${item.resourceId}`;
    window.open(classRoomUrl, '_blank');
    }
  };
  const fetchSessions = async () => {
    const payload = {
      startAt: todayStartAt,
      endAt: todayEndAt,
      nowPage:0,
      rowsPage: 100
    };
    const { data } = await getMySessions(payload);
    const sessions = data.sessions?.map(item => {
      let isActive = true;
      if (nowTime > item.endAt) isActive = false;
      const startAtText = format(item.startAt, 'HH:mm');
      const endAtText = format(item.endAt, 'HH:mm');
      const sessionInterval = `${startAtText} - ${endAtText}`;
      return {
        ...item,
        isActive,
        sessionInterval
      };
    }) || [];
    setSessionsData(sessions);
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  return (
    <UiSessionGroup>
      {
         sessionsData.map((item, index) => {
          return (
            <DashBoardCard
              key={index}
              title={item.name}
              groupName={item.groupName}
              hostName={item.hostName}
              isActive={item.isActive}
              sessionInterval={item.sessionInterval}
              defaultImg={Book}
              onClick={()=> item.isActive && goSessionReader(item)}
            />
          );
        })
      }
    </UiSessionGroup>
  );
};
