/* eslint-disable max-len */
import React, { useEffect,useMemo } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { stringify } from 'query-string';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { getPrivateQuizzes,getQuizzesOptions } from 'services/api/home/users';
import { Button, Checkbox,InlineSelect,Icon,Table,BsModal,DateTimePicker } from 'components';
import {
  Table as MatTable,
  Collapse,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Tab,
  TablePagination,
  TextField as MatTextField
 } from '@material-ui/core';
import { format } from 'date-fns';
import {
  getGroupOnlineQuizzes,
  terminateGroupOnlineQuizzes,
  createGroupOnlineQuizzes,
  updateGroupOnlineQuizzes,
  deleteGroupOnlineQuizzes,
  getGroupOnlineQuizzesOptions,
  createImmediateGroupOnlineQuizzes
} from 'services/api/home/quizzes';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import emptyImage from 'assets/images/empty.png';
import {
  UiHomeOnlineExamTable,
  UiTitle,
  UiTitleRow,
  UiContentRow,
  UiCell,
  UiTableBody,
  UiMatTabs,
  UiIconBox,
  UiPdf,
  UiPlane,
  UiNoDataBox,
  UiPictureBox,
  UiNoDataText,
  UiEditBox,
  UiTabBox,
  UiTabItem,
  UiFormBox
 } from './HomeOnlineExamTable.style';


/**
 * 線上測驗 V2
 */
 const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
});

const TEST_STATUS = {
  READY:'ready',
  TO_BE_CORRECTED:'toBeCorrected',
  FINISHED:'finished',
  MISSED:'missed',
  ANSWERED:'answered'
};

const quizzesSchema = {
  productInfoText: {
    name: '品項名稱',
    defaultValue: '-'
  },
  subjectText: {
    name: '科目',
    defaultValue: '-'
  },
  productNumberText: {
    name: '回數',
    defaultValue: '-'
  }
};

const CREATE_TYPE = {
  NOW:'now',
  RESERVE:'Reserve'
};

const StudentTable = ({data = [],changeCollapseStatus,actionHandle}) => {
  const classes = useRowStyles();
  return (
    <TableContainer>
      <MatTable>
        <TableHead>
          <UiTitleRow>
            <TableCell style={{width: '48px', padding: '16px 9px'}}/>
            <TableCell style={{width: '200px'}}>測驗名稱</TableCell>
            <TableCell>科目</TableCell>
            <TableCell>開始時間</TableCell>
            <TableCell>結束時間</TableCell>
            <TableCell>作答時間</TableCell>
            <TableCell></TableCell>
          </UiTitleRow>
        </TableHead>
        <TableBody>
          {
            data.map((onlineQuizzes,index) => {
              const {
                examId,
                examName,
                subject,
                hostNickname,
                productNumber,
                startAt,
                endAt,
                examPeriod,
                isCollapseOpen,
                status
              } = onlineQuizzes;

              return (
                <>
                  <UiContentRow className={classes.root} isCollapseOpen={isCollapseOpen}>
                    <TableCell style={{width: '48px', padding: '16px 9px'}}>
                      <IconButton size="small" onClick={() => changeCollapseStatus(examId,isCollapseOpen)}>
                        {isCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell style={{width: '200px'}}>{examName}</TableCell>
                    <TableCell>{subject.name}</TableCell>
                    <TableCell>{format(startAt,'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>{format(endAt,'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>{examPeriod}分</TableCell>
                    <TableCell>
                      {actionHandle(status,examId,hostNickname,endAt,startAt,examPeriod)}
                    </TableCell>
                  </UiContentRow>
                  <UiTitleRow>
                    <TableCell style={{ padding: 0, paddingLeft: '48px', width: '100%' }} colSpan={7}>
                      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                        <Box>
                          <MatTable size="small">
                            <TableHead>
                              <TableRow>
                                <UiCell style={{width: '200px'}}>回數</UiCell>
                                <UiCell>派卷老師</UiCell>
                              </TableRow>
                            </TableHead>
                            <UiTableBody>
                              <TableRow>
                                <UiCell>
                                  {productNumber.name}
                                </UiCell>
                                <UiCell>
                                  {hostNickname}
                                </UiCell>
                              </TableRow>
                            </UiTableBody>
                          </MatTable>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </UiTitleRow>
                </>
              );
            })
          }
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

const TeacherTable = ({data = [],changeCollapseStatus,actionHandle}) => {
  const classes = useRowStyles();
  return (
    <TableContainer>
      <MatTable>
        <TableHead>
          <UiTitleRow>
            <TableCell style={{width: '48px', padding: '16px 9px'}}/>
            <TableCell style={{width: '200px'}}>測驗名稱</TableCell>
            <TableCell>科目</TableCell>
            <TableCell>派卷老師</TableCell>
            <TableCell>應試人數</TableCell>
            <TableCell></TableCell>
          </UiTitleRow>
        </TableHead>
        <TableBody>
          {
            data.map((onlineQuizzes,index) => {
              const {
                examId,
                examName,
                subject,
                hostNickname,
                testedUsers,
                productNumber,
                startAt,
                endAt,
                examPeriod,
                isCollapseOpen,
                groupUsers,
                status,
                hostId
              } = onlineQuizzes;

              return (
                <>
                  <UiContentRow className={classes.root} isCollapseOpen={isCollapseOpen}>
                    <TableCell style={{width: '48px', padding: '16px 9px'}}>
                      <IconButton size="small" onClick={() => changeCollapseStatus(examId,isCollapseOpen)}>
                        {isCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell style={{width: '200px'}}>{examName}</TableCell>
                    <TableCell>{subject.name}</TableCell>
                    <TableCell>{hostNickname}</TableCell>
                    <TableCell>{ testedUsers.length } / { groupUsers.length }</TableCell>
                    <TableCell>
                      {actionHandle(status,examId,hostNickname,endAt,startAt,examPeriod,testedUsers.length,hostId)}
                    </TableCell>
                  </UiContentRow>
                  <UiTitleRow>
                    <TableCell style={{ padding: 0, paddingLeft: '48px' }} colSpan={7}>
                      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                        <Box>
                          <MatTable size="small">
                            <TableHead>
                              <TableRow>
                                <UiCell>回數</UiCell>
                                <UiCell>開始時間</UiCell>
                                <UiCell>結束時間</UiCell>
                                <UiCell>作答時間</UiCell>
                              </TableRow>
                            </TableHead>
                            <UiTableBody>
                              <TableRow>
                                <UiCell>
                                  {productNumber.name}
                                </UiCell>
                                <UiCell>
                                  {format(startAt,'yyyy-MM-dd HH:mm')}
                                </UiCell>
                                <UiCell>
                                {format(endAt,'yyyy-MM-dd HH:mm')}
                                </UiCell>
                                <UiCell>
                                {examPeriod}分
                                </UiCell>
                              </TableRow>
                            </UiTableBody>
                          </MatTable>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </UiTitleRow>
                </>
              );
            })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export const HomeOnlineExamTable = () => {
  const [{ myClasses }] = useClass();
  const [{ profile }] = useUser();
  const isVisible = usePageVisibility();
  const { setAlert } = useAlert();
  const { dataInfo: myClassInfo } = myClasses;
  const { classId } = useParams();
  const [{
    onlineQuizzesData,
    onlineQuizzesTotal,
    onlineQuizzesNowPage,
    onlineQuizzesRowsPage,
    tabIndex,
    unansweredOnly,
    quizzes,
    quizzesTotal,
    startAt,
    endAt,
    examPeriod,
    isOpen,
    nowPage,
    rowsPage,
    subjectCode,
    productCode,
    subjectOptions,
    productInfoOptions,
    examPaperId,
    onlineQuizzesSubjectCode,
    onlineQuizzesProductCode,
    onlineQuizzesSubjectOptions,
    onlineQuizzesProductOptions,
    isEditOpen,
    answerTime,
    editStartTime,
    editEndTime,
    targetExamId,
    isDeleteModal,
    createExamType,
    isLoading
  },setState] = useSetState({
    onlineQuizzesData: [],
    onlineQuizzesTotal:0,
    onlineQuizzesNowPage: 0,
    onlineQuizzesRowsPage: 10,
    tabIndex: 0,
    unansweredOnly:false,
    quizzes:[],
    quizzesTotal:0,
    startAt:null,
    endAt:null,
    examPeriod:null,
    isOpen:false,
    nowPage: 0,
    rowsPage: 10,
    subjectCode: '',
    productCode: '',
    subjectOptions:[],
    productInfoOptions:[],
    examPaperId:'',
    onlineQuizzesSubjectCode: '',
    onlineQuizzesProductCode: '',
    onlineQuizzesSubjectOptions: [],
    onlineQuizzesProductOptions: [],
    isEditOpen:false,
    answerTime:null,
    editStartTime:null,
    editEndTime:null,
    targetExamId:'',
    isDeleteModal: false,
    createExamType:CREATE_TYPE.NOW,
    isLoading:false
  });

  const toPdf = url => {
    window.open(url);
  };

  const changeQuizzesPage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      onlineQuizzesNowPage:0,
      onlineQuizzesRowsPage:parseInt(event.target.value, 10),
    });
  };

  const handleChangePage = (event, newPage) => {
    setState({
      onlineQuizzesNowPage:newPage
    });
  };

  const ActionQuizzesComponents = obj => {
    return (
      <UiIconBox>
        <UiPdf onClick={() => toPdf(obj.params.previewUrl)}>
          <Icon.Svg name="Pdf" title="試卷預覽"/>
        </UiPdf>
        <div></div>
        <UiPlane onClick={() => setState({isOpen: true, examPaperId: obj.params.itemId}) }>
          <Icon.Svg name="Plane" title="我要派卷"/>
        </UiPlane>
      </UiIconBox>
    );
  };

  const onCancel = () => {
    setState({
      isOpen: false,
      examPaperId:'',
      startAt:null,
      endAt:null,
      examPeriod:null
    });
  };

  const onClick = () => {
    fetchCreateGroupOnlineQuizzes();
  };

  const onChange = (value,key) => {
    if(key === 'examPeriod' && value.indexOf('.') !== -1) {
      setState({
        examPeriod
      });
      return;
    }
    if(key === 'endAt' && ((value - startAt) < parseInt(examPeriod) * 60 * 1000) && value !== null) return;
    setState({
      [key]:value
    });
  };

  const onChangeCreateType = type => {
    setState({
      createExamType:type
    });
  };

  const selectChangeHandler = (value,key) => {
    if(key === 'subjectCode') {
      setState({ productCode: '', nowPage: 0 });
    }
    if(key === 'productCode') {
      setState({ nowPage: 0 });
    }
    if(key === 'onlineQuizzesSubjectCode') {
      setState({ onlineQuizzesNowPage: 0,onlineQuizzesProductCode:'' });
    }
    if(key === 'onlineQuizzesProductCode') {
      setState({ onlineQuizzesNowPage: 0 });
    }
    setState({ [key]: value });
  };

  const fetchQuizzes = async (params) => {
    const { data,isSuccess } = await getPrivateQuizzes(params);
    if(isSuccess){
      const quizzesData = data.quizzes.map(quiz=>{
        return {
          ...quiz,
          subjectText:quiz.subject.name,
          productInfoText:quiz.productInfo.name,
          productNumberText:`第${quiz.productNumber.code}回${quiz.productNumber.name}`
        };
      });

      setState({
        quizzes: quizzesData,
        quizzesTotal: data.total,
      });
    }else{
      setState({
        quizzes: [],
        quizzesTotal:0,
      });
    }
  };

  const fetchCreateGroupOnlineQuizzes = async () => {
    setState({
      isLoading:true
    });
    const immediatePayload = {
      examPeriod:parseInt(examPeriod),
      examPaperId
    };
    const payload = {
      startAt,
      endAt,
      examPeriod:parseInt(examPeriod),
      examPaperId
    };
    const { isSuccess } =
      createExamType === CREATE_TYPE.NOW ?
      await createImmediateGroupOnlineQuizzes(classId, immediatePayload) :
      await createGroupOnlineQuizzes(classId, payload);
    if(isSuccess) {
      setAlert('派卷成功!', 'success');
      const params = {
        nowPage:onlineQuizzesNowPage,
        rowsPage:onlineQuizzesRowsPage,
        unansweredOnly,
        subjectCode:onlineQuizzesSubjectCode,
        productCode:onlineQuizzesProductCode,
      };
      fetchOnlineQuizzes(params);
      setState({
        examPeriod:null,
        endAt:null,
        startAt:null,
        examPaperId:'',
        isLoading:false,
        isOpen:false
      });
    } else {
      setAlert('派卷失敗!', 'wrarning');
      setState({
        isLoading:false
      });
    }
  };

  const fetchOnlineQuizzes = async params => {
    const { isSuccess, data } = await getGroupOnlineQuizzes(classId,params);
    if(isSuccess) {
      setState({
        onlineQuizzesData: data.exams.map(exam => {
          return {
            ...exam,
            isCollapseOpen: false
          };
        }),
        onlineQuizzesTotal:data.total,
      });
    }
  };

  const fetchQuizzesOptions = async () => {
    const { data:{ subjects,subjectProducts } } = await getQuizzesOptions();
    const defaultOption = [
      {
        name:'全部科目',
        value:''
      }
    ];
    const subjectData = defaultOption.concat(subjects.map(subject=>{
      return {
        name:subject.name,
        value:subject.code
      };
    }));
    setState({
      subjectOptions:subjectData,
      productInfoOptions:subjectProducts,
    });
  };

  const fetchExamsOptions = async () => {
    const { data:{ subjects,subjectProducts } } = await getGroupOnlineQuizzesOptions(classId);
    const defaultOption = [
      {
        name:'全部科目',
        value:''
      }
    ];
    const subjectData = defaultOption.concat(subjects.map(subject=>{
      return {
        name:subject.name,
        value:subject.code
      };
    }));
    setState({
      onlineQuizzesSubjectOptions: subjectData,
      onlineQuizzesProductOptions: subjectProducts,
    });
  };

  const productInfoOptionsData = useMemo(()=>{
    const defaultProdOption = [
      {
        name:'全部品項',
        value:''
      }
    ];
    if(!subjectCode || !productInfoOptions) return defaultProdOption;

    const productInfoData = defaultProdOption.concat(productInfoOptions[subjectCode].map(product=>{
      return {
        name:product.name,
        value:product.code
      };
    }));
    return productInfoData;
  },[subjectCode]);

  const onlineQuizzesProductOptionsData = useMemo(()=>{
    const defaultProdOption = [
      {
        name:'全部品項',
        value:''
      }
    ];
    if(!onlineQuizzesSubjectCode || !onlineQuizzesProductOptions) return defaultProdOption;

    const productInfoData = defaultProdOption.concat(onlineQuizzesProductOptions[onlineQuizzesSubjectCode].map(product=>{
      return {
        name:product.name,
        value:product.code
      };
    }));
    return productInfoData;
  },[onlineQuizzesSubjectCode]);

  const changeCollapseStatus = (examId,status) => {
    const nextOnlineQuizzesData = onlineQuizzesData.map(quizzes => {
      if(quizzes.examId === examId) {
        return {
          ...quizzes,
          isCollapseOpen:!status
        };
      }else{
        return {
          ...quizzes,
          isCollapseOpen: false
        };
      }
    });
    setState({
      onlineQuizzesData:nextOnlineQuizzesData
    });
  };

  const getTabIndexHandler = (e,tabIndex) => {
    setState({
      tabIndex
    });
  };

  const onChangeHandle = (value,key) => {
    if(key === 'editEndTime' && ((value - editStartTime) < answerTime * 60 * 1000) && value !== null) return;
    if(key === 'unansweredOnly') {
      setState({
        onlineQuizzesNowPage:0
      });
    }
    setState({
      [key]: value
    });
  };

  const modalToggle = () => {
    if(isEditOpen){
      setState({
        answerTime: null,
        editStartTime: null,
        editEndTime: null,
      });
    }
    setState({
      isEditOpen : !isEditOpen,
    });
  };

  const onModalOk = async () => {
    const payload = {
      examPeriod: parseInt(answerTime),
      startAt: editStartTime,
      endAt: editEndTime
    };
    const { isSuccess } = await updateGroupOnlineQuizzes(classId,targetExamId,payload);
    if(isSuccess){
      setAlert('編輯測驗成功!', 'success');
      const params = {
        nowPage:onlineQuizzesNowPage,
        rowsPage:onlineQuizzesRowsPage,
        unansweredOnly,
        subjectCode:onlineQuizzesSubjectCode,
        productCode:onlineQuizzesProductCode,
      };
      modalToggle();
      fetchOnlineQuizzes(params);
    }else{
      setAlert('編輯測驗失敗!', 'wrarning');
    }
  };

  const fetchDeleteGroupOnlineQuizzes = async () => {
    const { isSuccess } = await deleteGroupOnlineQuizzes(classId, targetExamId);
    if(isSuccess){
      setAlert('刪除測驗成功!', 'success');
      setState({isDeleteModal: false});
      const params = {
        nowPage:onlineQuizzesNowPage,
        rowsPage:onlineQuizzesRowsPage,
        unansweredOnly,
        subjectCode:onlineQuizzesSubjectCode,
        productCode:onlineQuizzesProductCode,
      };
      fetchOnlineQuizzes(params);
    }else{
      setAlert('刪除測驗失敗!', 'wrarning');
    }
  };

  const actionHandle = (status,examId,hostNickname,endAt,startAt,examPeriod,testedUsers,hostId) => {
    const testedUsersDisabled = testedUsers > 0 ? false : true;
    const nowTime = new Date().getTime();
    const isCanEdit = nowTime > startAt;
    const isOwner = hostId === profile.id;
    const goExamUrl = () => {
      const { dataInfo:{ attendeeNumbers } } = myClasses;
      const params = {
        examId,
        userId:profile.id,
        username:profile.nickname,
        teacherName:hostNickname
      };
      if(attendeeNumbers && attendeeNumbers[profile.id]) {
        params.seatNum = attendeeNumbers[profile.id];
      }
      const querystring = stringify(params);
      const openUrl = window.open('', '_blank');
      const examUrl = `${process.env.REACT_APP_QUIZZES_DOMAIN}/user/answer?${querystring}`;
      openUrl.location = examUrl;
    };
    const goResultUrl = () => {
      const openUrl = window.open('', '_blank');
      const resultUrl = myClassInfo.userType === 'student' ?
        `${process.env.REACT_APP_QUIZZES_DOMAIN}/user/result?examId=${examId}&userId=${profile.id}&userName=${profile.nickname}&teacherName=${hostNickname}`:
        `${process.env.REACT_APP_QUIZZES_DOMAIN}/analysisForm?examId=${examId}&userId=${profile.id}`;
      openUrl.location = resultUrl;
    };

    const goCorrected = () => {
      const openUrl = window.open('', '_blank');
      const correctedUrl = `${process.env.REACT_APP_QUIZZES_DOMAIN}/teacher/mark?examId=${examId}`;
      openUrl.location = correctedUrl;
    };

    const endAnswerHandle = async () => {
      const { isSuccess } = await terminateGroupOnlineQuizzes(classId,examId);
      if(isSuccess) {
        setAlert('結束測驗成功!', 'success');
        const params = {
          nowPage:onlineQuizzesNowPage,
          rowsPage:onlineQuizzesRowsPage,
          unansweredOnly,
          subjectCode:onlineQuizzesSubjectCode,
          productCode:onlineQuizzesProductCode,
        };
        fetchOnlineQuizzes(params);
      }else{
        setAlert('結束測驗失敗!', 'wrarning');
      }
    };

    const editExam = async () => {
      if(isCanEdit || !isOwner) return;
      setState({
        isEditOpen: true,
        targetExamId:examId,
        answerTime:examPeriod,
        editStartTime:startAt,
        editEndTime:endAt,
       });
    };

    if(myClassInfo.userType === 'student'){
      switch(status){
        case TEST_STATUS.READY:
          return (
            <Button buttonColor="new" onClick={goExamUrl}>開始作答</Button>
          );
        case TEST_STATUS.FINISHED:
          return (
            <Button buttonColor="cancel" onClick={goResultUrl}>測驗結果</Button>
          );
        case TEST_STATUS.ANSWERED:
          return (
            <Button disabled={true}>作答完成</Button>
          );
        case TEST_STATUS.MISSED:
          return (
            <Button disabled={true}>缺考</Button>
          );
        default:
          return null;
      }
    }else{
      switch(status){
        case TEST_STATUS.READY:
          return (
            <Box display="flex" alignItems="center">
              <UiEditBox disabled={!isCanEdit}>
                <span onClick={editExam}>
                  <Icon.Svg name="Edit" color={(isCanEdit || !isOwner) ? '#D5D7DE': '#000'} title="編輯測驗"/>
                </span>
                <div></div>
                <span
                  onClick={() => {
                    isOwner && setState({ isDeleteModal: true, targetExamId: examId });
                  }}>
                  <Icon.Svg name="Delete" color={(isCanEdit || !isOwner) ? '#D5D7DE': '#000'} title="刪除測驗"/>
                </span>
              </UiEditBox>
              <Button disabled={!isOwner || !isCanEdit} buttonColor="error" onClick={endAnswerHandle}>結束作答</Button>
            </Box>
          );
        case TEST_STATUS.FINISHED:
          return (
            <Box display="flex" alignItems="center">
              <UiEditBox disabled={!isCanEdit}>
                <span>
                  <Icon.Svg name="Edit" color={(isCanEdit || !isOwner) ? '#D5D7DE': '#000'} title="編輯測驗"/>
                </span>
                <div></div>
                <span onClick={() => {isOwner && setState({ isDeleteModal: true, targetExamId: examId, });}}>
                  <Icon.Svg name="Delete" color={isOwner ? '#000' : '#D5D7DE'} title="刪除測驗"/>
                </span>
              </UiEditBox>
              <Button buttonColor="cancel" onClick={goResultUrl} disabled={testedUsersDisabled}>測驗結果</Button>
            </Box>
          );
        case TEST_STATUS.TO_BE_CORRECTED:
          return (
            <Box display="flex" alignItems="center">
              <UiEditBox disabled={!isCanEdit}>
                <span>
                  <Icon.Svg name="Edit" color={isCanEdit ? '#D5D7DE': '#000'} title="編輯測驗"/>
                </span>
                <div></div>
                <span>
                  <Icon.Svg name="Delete" color={isCanEdit ? '#D5D7DE': '#000'} title="刪除測驗"/>
                </span>
              </UiEditBox>
              <Button buttonColor="new" onClick={goCorrected} disabled={(testedUsersDisabled && !isOwner)}>批改非選</Button>
            </Box>
          );
        default:
          return null;
      }
    }
  };

  const submitQuizzesSearch = () => {
    const params = {
      nowPage,
      rowsPage,
      subjectCode,
      productCode,
    };
    fetchQuizzes(params);
  };

  const submitExamsSearch = () => {
    const params = {
      nowPage:onlineQuizzesNowPage,
      rowsPage:onlineQuizzesRowsPage,
      unansweredOnly,
      subjectCode:onlineQuizzesSubjectCode,
      productCode:onlineQuizzesProductCode,
    };
    fetchOnlineQuizzes(params);
  };

  useEffect(()=>{
    const params = {
      nowPage:onlineQuizzesNowPage,
      rowsPage:onlineQuizzesRowsPage,
      unansweredOnly,
      subjectCode:onlineQuizzesSubjectCode,
      productCode:onlineQuizzesProductCode,
    };
    if(isVisible) fetchOnlineQuizzes(params);
  },[
    onlineQuizzesNowPage,
    onlineQuizzesRowsPage,
    unansweredOnly,
    isVisible
  ]);

  useEffect(() => {
    if(myClassInfo.userType !== 'teacher') return;
    const params = {
      nowPage,
      rowsPage,
      subjectCode,
      productCode,
    };

    fetchQuizzes(params);
  }, [nowPage, rowsPage,myClassInfo.userType]);

  useEffect(()=>{
    fetchQuizzesOptions();
    fetchExamsOptions();
  },[]);

  useEffect(()=>{
    if(myClassInfo.userType === 'student'){
      setState({
        tabIndex:1
      });
    }
  },[myClassInfo.userType]);

  return (
    <UiHomeOnlineExamTable>
      <UiTitle>線上派卷</UiTitle>
      {myClassInfo.userType === 'teacher' &&
      <UiMatTabs
        value={tabIndex}
        onChange={getTabIndexHandler}
      >
        <Tab label="試卷列表" />
        <Tab label="測驗列表" />
      </UiMatTabs>}
      {
        (subjectOptions.length > 0 || onlineQuizzesSubjectOptions.length > 0) &&
        (myClassInfo.userType === 'student' ?
          <Box py={2} display="flex">
          <InlineSelect
              label="科目"
              placeholder="科目"
              options={onlineQuizzesSubjectOptions}
              value={onlineQuizzesSubjectCode}
              onChange={ value => selectChangeHandler( value,'onlineQuizzesSubjectCode')}
              style={{marginRight: '8px'}}
            />
            <Button onClick={submitExamsSearch}>搜尋</Button>
            <Checkbox
              style={{marginLeft: 'auto'}}
              label="僅顯示未作答測驗"
              checkedValue={true}
              onChange={value=>{
                onChangeHandle(value,'unansweredOnly');
              }}
              checked={unansweredOnly}
            />
          </Box>:
          <Box py={2}>
            {
              tabIndex === 0 ?
              <Box display="flex">
                <InlineSelect
                  label="科目"
                  placeholder="科目"
                  options={subjectOptions}
                  value={subjectCode}
                  onChange={ value => selectChangeHandler( value,'subjectCode')}
                  style={{marginRight: '8px'}}
                />
                <InlineSelect
                  label="品項"
                  placeholder="品項"
                  options={productInfoOptionsData}
                  value={productCode}
                  disabled={subjectCode=== ''}
                  onChange={ value => selectChangeHandler( value ,'productCode')}
                  style={{marginRight: '8px'}}
                />
                <Button onClick={submitQuizzesSearch}>搜尋</Button>
              </Box>:
              <Box display="flex">
                <InlineSelect
                  label="科目"
                  placeholder="科目"
                  options={onlineQuizzesSubjectOptions}
                  value={onlineQuizzesSubjectCode}
                  onChange={ value => selectChangeHandler( value,'onlineQuizzesSubjectCode')}
                  style={{marginRight: '8px'}}
                />
                <InlineSelect
                  label="品項"
                  placeholder="品項"
                  options={onlineQuizzesProductOptionsData}
                  value={onlineQuizzesProductCode}
                  disabled={onlineQuizzesSubjectCode=== ''}
                  onChange={ value => selectChangeHandler( value ,'onlineQuizzesProductCode')}
                  style={{marginRight: '8px'}}
                />
                <Button onClick={submitExamsSearch}>搜尋</Button>
              </Box>
            }
          </Box>)
      }
      {
        tabIndex === 0 ?
        <>
          {
            !quizzes && <UiNoDataBox>
              <UiPictureBox path={emptyImage} />
              <UiNoDataText>
                <div>您尚未購買任何考卷</div>
                <div>如欲使用此功能請洽各區南一業務購買</div>
              </UiNoDataText>
            </UiNoDataBox>
          }
          {
            quizzes && <>
              <Table
                data={quizzes}
                schema={quizzesSchema}
                changePage_Rows={changeQuizzesPage_Rows}
                totalPage={quizzesTotal}
                ActionComponents={ActionQuizzesComponents}
                nowPage={nowPage}
              />
              <BsModal
                title="我要派卷"
                height='536px'
                open={isOpen}
                onOk={onClick}
                onCancel={onCancel}
                isLoading={isLoading}
                okText="派卷"
              >
              <UiTabBox>
                <UiTabItem
                  className={createExamType === CREATE_TYPE.NOW ? 'active' : ''}
                  onClick={()=> onChangeCreateType(CREATE_TYPE.NOW)}
                >
                  <Icon.Svg name="Rocket" color={createExamType === CREATE_TYPE.NOW ? '#fff':'#8B90A0'}/>
                  <span>立即派卷</span>
                </UiTabItem>
                <UiTabItem
                  className={createExamType === CREATE_TYPE.RESERVE ? 'active' : ''}
                  onClick={()=> onChangeCreateType(CREATE_TYPE.RESERVE)}
                >
                  <Icon.Svg name="Calendar"color={createExamType === CREATE_TYPE.RESERVE ? '#fff':'#8B90A0'}/>
                  <span>預約派卷</span>
                </UiTabItem>
              </UiTabBox>
              <UiFormBox position={createExamType === CREATE_TYPE.NOW ? '24%' : '72%'}>
                <MatTextField
                  label="作答時間(分鐘)*"
                  variant="outlined"
                  type="number"
                  style={{width: '70%', margin: '10px 0'}}
                  value={examPeriod || null}
                  onChange={e => onChange(e.target.value,'examPeriod')}
                />
                {createExamType === CREATE_TYPE.RESERVE &&
                <DateTimePicker
                  label="開始時間*"
                  style={{width: '70%', margin: '10px 0'}}
                  value={startAt || null}
                  onChange={value => onChange(value,'startAt')}
                />}
                {
                  (examPeriod && startAt && createExamType === CREATE_TYPE.RESERVE) &&
                  <DateTimePicker
                    label="結束時間*"
                    style={{width: '70%', margin: '10px 0'}}
                    value={endAt || null}
                    onChange={value => onChange(value,'endAt')}
                  />
                }
              </UiFormBox>
              </BsModal>
            </>
          }
        </> :
        <>
        {(myClassInfo.userType === 'student' ?
          (onlineQuizzesData.length > 0 ?
          <>
            <StudentTable
              data={onlineQuizzesData}
              actionHandle={actionHandle}
              changeCollapseStatus={changeCollapseStatus}
            />
             <Box py={2} display="flex" justifyContent="flex-end">
              <TablePagination
                count={onlineQuizzesTotal}
                page={onlineQuizzesNowPage}
                onChangePage={handleChangePage}
                rowsPerPage={onlineQuizzesRowsPage}
                labelRowsPerPage={'每頁顯示'}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                component="div"
              />
            </Box>
          </>
           :
          <UiNoDataBox>
              <UiPictureBox path={emptyImage} />
              <UiNoDataText>
                <div>目前老師尚無指派測驗</div>
              </UiNoDataText>
            </UiNoDataBox>
          ) :
          (onlineQuizzesData.length > 0 ?
          <>
            <TeacherTable
              data={onlineQuizzesData}
              actionHandle={actionHandle}
              changeCollapseStatus={changeCollapseStatus}
            />
            <Box py={2} display="flex" justifyContent="flex-end">
              <TablePagination
                 count={onlineQuizzesTotal}
                 page={onlineQuizzesNowPage}
                 onChangePage={handleChangePage}
                 rowsPerPage={onlineQuizzesRowsPage}
                 labelRowsPerPage={'每頁顯示'}
                 onChangeRowsPerPage={handleChangeRowsPerPage}
                 component="div"
              />
            </Box>
          </>
          :
          <UiNoDataBox>
          <UiPictureBox path={emptyImage} />
          <UiNoDataText>
            <div>目前老師尚無指派測驗</div>
          </UiNoDataText>
        </UiNoDataBox>))}
        <BsModal
          title="編輯測驗"
          open={isEditOpen}
          onOk={onModalOk}
          onCancel={modalToggle}
          okText="確定"
        >
        <MatTextField
          label="作答時間(分鐘)*"
          variant="outlined"
          type="number"
          style={{width: '70%', margin: '10px 0'}}
          value={answerTime}
          onChange={ e => onChangeHandle(e.target.value,'answerTime') }
        />
          <DateTimePicker
            label="開始時間*"
            style={{width: '70%', margin: '10px 0'}}
            value={editStartTime}
            onChange={value => onChangeHandle(value,'editStartTime')}
          />
          {
            (answerTime && editStartTime) &&
            <DateTimePicker
              label="結束時間*"
              style={{width: '70%', margin: '10px 0'}}
              value={editEndTime}
              onChange={value => onChangeHandle(value,'editEndTime')}
            />
          }
        </BsModal>
        <BsModal open={isDeleteModal} onOk={fetchDeleteGroupOnlineQuizzes} onCancel={() => {setState({isDeleteModal: !isDeleteModal});}}>
          刪除此測驗後，學生測驗結果將不會保留，是否仍要刪除?
        </BsModal>
        </>
      }
    </UiHomeOnlineExamTable>
  );
};
