/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';

import { stringify } from 'query-string';
import { Table, IconInput, Select, TimePicker, Button, Prompt, Loading, OutlineButton } from 'components';
import { useSessions } from 'store/sessions';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { keepSessionReport } from 'services/api/organization/session';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { useDateInterval } from 'utils/hooks/useDateInterval';
import { useParams, useHistory } from 'react-router-dom';
import { EDIT_STATUS, ORGANIZATION_SETTINGS_VALUE, EXAM_PROJECT_NAME, PREVIEW_TIMESTAMP, } from 'constants/index';
import { UiTable, UiActionBox, UiflexBox } from 'styles';

import {UiSessionsTableButtonBox, UiTitle} from './HomeSessionsTable.style';


/**
 * 前台課程表
 */

const schema = {
  name: {
    name: '課程名稱',
    defaultValue: ''
  },
  subject:{
    name:'科目',
    defaultValue:'--'
  },
  startDate: {
    name: '日期',
    defaultValue: ''
  },
  startTime: {
    name: '時間',
    defaultValue: ''
  },
  hostName: {
    name: '教師',
    defaultValue: ''
  }
};

const NOW = 'NOW';
const PROCESSING = 'PROCESSING';
const PAST = 'PAST';
const FUTURE = 'FUTURE';
const ALL = 'ALL';

const selectOptions = [
  {
    name: '今日課程',
    value: NOW
  },
  {
    name: '當前課程',
    value: PROCESSING
  },
  {
    name: '已上課程',
    value: PAST
  },
  {
    name: '未來課程',
    value: FUTURE
  },
  {
    name: '所有課程',
    value: ALL
  }
];


export const HomeSessionsTable = () => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const { organizationId, classId } = useParams();
  const [{ myOrganization,profile }] = useUser();
  const [{ myClasses }] = useClass();
  const { dataInfo: { isOwner, userType, groupHostCreateSessionSetting,groupRole } } = myClasses;
  const [,
    { todayInterval, processingInterval, pastInterval, futureInterval, allInterval }
  ] = useDateInterval();
  const [{ sessions }, { getSessions,attendanceSession }] = useSessions();
  const [
    {
      dateInterval,
      name,
      nowPage,
      rowsPage,
      startAt,
      endAt,
      isListLoading,
      sessionAttendanceManagementSetting,
      isClick,
    }, setState] = useSetState({
      dateInterval: 'now',
      name: '',
      nowPage: 0,
      rowsPage: 10,
      startAt: null,
      endAt: null,
      officialName: '',
      isListLoading: false,
      sessionAttendanceManagementSetting: ORGANIZATION_SETTINGS_VALUE.NONE,
      isClick:true
    });
  useEffect(() => {
    if (myOrganization.isLoaded) {
      const { officialName,sessionAttendanceManagementSetting } = myOrganization.organization;
      setState({
        officialName,
        sessionAttendanceManagementSetting
      });
    }
  }, [myOrganization]);
  const addTeacherToClass = () => {
    history.push(`/home${organizationId ? `/${organizationId}` : ''}/class/${classId}/sessions/${EDIT_STATUS.CREATE}`);
  };
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onchangeHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const onSelectChangeHandler = selected => {
    let dateParams = {};
    setState({
      nowPage: 0
    });
    switch (selected) {
      case NOW:
        dateParams = { ...todayInterval() };
        break;
      case PAST:
        dateParams = { ...pastInterval() };
        break;
      case PROCESSING:
        dateParams = { ...processingInterval() };
        break;
      case FUTURE:
        dateParams = { ...futureInterval() };
        break;
      case ALL:
        dateParams = { ...allInterval() };
        break;
      default:
        break;
    }
    setState({
      dateInterval: selected,
      ...dateParams
    });
  };

  useEffect(() => {
    (async () => {
      setState({ isListLoading: true });
      await getSessions({ nowPage, rowsPage, name, startAt, endAt });
      setState({ isListLoading: false });
    })();
  }, [nowPage, rowsPage, name, startAt, endAt]);

  const btnText = (type) => {
    switch (type) {
      case 'before10':
        return '進入教室';
      case 'noStart':
      case 'before20':
        return userType === 'teacher' ? '進入教室' : '預習課程';
      case 'after90':
        return '回到課堂';
      case 'end':
        return userType === 'teacher' ? '查看課程' : '複習課程';
      default:
        break;
    }

  };

  const ActionComponents = ({ params }) => {
    const {
      isCanEnterClass: { type, canEnter },
      resourceId,
      id,
      groupId,
      groupName,
      timeSpanId,
      isPremium,
      hostName,
      hostAttendance,
      preExamId,
      postExamId,
      type: sessionsType,
      users,
      hostId,
     } = params;
    const usersLength = users?.length || 0;
    const ButtonClick = () => {
      const classRoomUrl =
        `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceId}`;
      window.open(classRoomUrl, '_blank');
    };
    const openExam = (testId) => {
      const openUrl = window.open('', '_blank');
      const url = `${process.env.REACT_APP_TEST_DOMAIN}?project=${EXAM_PROJECT_NAME}&range=${testId}&user=${profile.id}&roomId=${id}&teacherName=${hostName}&username=${profile.nickname}`;
      openUrl.location = url;
    };
    const attendanceHandle = async () => {
      if(!isClick) return;
      setState({
        isClick:false
      });
      let checkType = 'checkIn';
      if(hostAttendance?.checkInAt) checkType = 'checkOut';
      await attendanceSession(id,timeSpanId,{
        [checkType]:true
      });
      setTimeout(()=>{
        setState({
          isClick:true
        });
      },1000);
    };
    const checkType = useMemo(()=>{
      if(sessionAttendanceManagementSetting !== ORGANIZATION_SETTINGS_VALUE.HOST || params.hostId !== profile.id){
        return {
          type:'notShow'
        };
      }

      if(hostAttendance?.checkOutAt){
        return {
          type:'finish',
          name:'簽退完成'
        };
      }else if(hostAttendance?.checkInAt){
        return {
          type:'needCheckOut',
          name:'教師簽退'
        };
      }else{
        if(type === 'end'){
          return {
            type:'finish',
            name:'課程結束'
          };
        }else{
          return {
            type:'needCheckIn',
            name:'教師簽到'
          };
        }

      }

    },[hostAttendance]);

    // 更新課堂報表
    const fetchKeepSessionReport = async () => {
      const payload = {
        sessionId: id,
        timeSpanId,
      };
      const { isSuccess } = await keepSessionReport(payload);
      if (!isSuccess) {
        setAlert('取得課堂報表失敗!', 'error');
      } 
    };

    const goExamResultListPage = () => {
      history.push(`sessions/${id}/exam-result`);
    };

    const enterInteractiveSession = () => {
      const isCallReportApi = hostId === profile.id || users.some(id => id === profile.id);
      if (isCallReportApi) {
        fetchKeepSessionReport();
      }

      let classType = '';
      switch(sessionsType){
        case 'interactive':
          classType = usersLength <= 1 ? 'single' : 'group';
          break;
        case 'collaborative':
          classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
          break;
        case 'video':
          classType = 'group';
          break;
        default :
          break;
      }
      const querystring = stringify({
        bookIds: [resourceId],
        classType: classType,
        role: userType,
        userName: profile.nickname,
        userId: profile.id
      },{ arrayFormat: 'index' });
      window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
    };

    const reviseVideoOnDemand = () => {
      window.open(`${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${id}`);
    };

    const gotoSessionReport = () => {
      history.push({
        pathname: `/home/class/${groupId}/sessions/${id}/timespan/${timeSpanId}/report`,
        state: {
          className: groupName,
          hostName,
          resourceId,
          sessionType: sessionsType,
        }
      });
    };

    const enterGroup = (t) => {
      switch(t){
        case 'noStart':
          return (
            <Button disabled>課程尚未開始</Button>
            );
        case 'before20':
          return (
            userType === 'student' ?
            <Button
              onClick={ButtonClick}
              buttonColor="normal"
            >
              預習課程
            </Button>:
            <Button disabled>課程尚未開始</Button>
          );
        case 'before10':
          return (
            <Button
              onClick={() => enterInteractiveSession('group')}
              buttonColor="normal"
            >
              進入教室
            </Button>
          );
        case 'after90':
          return (
            <Button
              onClick={() => enterInteractiveSession('group')}
              buttonColor="normal"
            >
              回到課堂
            </Button>
          );
        case 'end':
          return (
            <Button disabled>課程結束</Button>
          );
        default:
          return null;
      }
    };

    /* 判斷現在是否為預課時間 */
    const isPreviewTime = () => {
      const { startAt } = params;       // 上課開始時間
      const nowTimestamp = Date.now();  // 現在時間
      const previewStartTime = startAt - PREVIEW_TIMESTAMP;  // 預課開始時間

      if(nowTimestamp >= previewStartTime) return true;
    };

    return (
      <UiSessionsTableButtonBox>
        {
          (sessionsType === 'interactive' || sessionsType === 'video' || sessionsType === 'collaborative') ? (
            <>
              {enterGroup(type)}
              {
                // 此為 OneBroad 開課
                type === 'end' &&
                <Button
                  buttonColor="new"
                  onClick={()=> reviseVideoOnDemand()}
                >觀看錄影
                </Button>
              }
              {isPreviewTime() &&
                <Button
                  onClick={()=> gotoSessionReport()}
                >
                  課堂報告
                </Button>
              }
            </>
          ) : (
            <>
              <Button
                onClick={ButtonClick}
                endIcon={isPremium ? 'videocam' : ''}
                buttonColor={canEnter ? 'normal' : 'new'}
              >
                {btnText(type)}
              </Button>
              {
                (checkType.type !== 'notShow' && type !== 'noStart') &&
                <OutlineButton
                  buttonColor={checkType.type === 'finish' ? 'disable' : 'normal'}
                  onClick={() => checkType.type !== 'finish' && attendanceHandle()}
                  disabled={checkType.type === 'finish'}
                  loading={!isClick}
                >
                  {checkType.name}
                </OutlineButton>
              }
              {
                (preExamId && userType === 'student') &&
                <Button
                  buttonColor="visibility"
                  onClick={()=>openExam(preExamId)}
                >課前測驗
                </Button>
              }
              {
                (postExamId && userType === 'student') &&
                <Button
                  buttonColor="visibility"
                  onClick={()=>openExam(postExamId)}
                >課後測驗
                </Button>
              }
              {
                ((preExamId || postExamId) && userType === 'teacher') &&
                <Button
                  buttonColor="visibility"
                  onClick={()=> goExamResultListPage()}
                >測驗結果
                </Button>
              }
            </>
          )
        }
      </UiSessionsTableButtonBox>
    );
  };

  const operatorAuth = useMemo(() => {
    const { groupOwnerCreateSessionSetting } = myOrganization?.organization || {};
    return isOwner && groupOwnerCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  },[isOwner,myOrganization]);

  const isStaffCanCreateSession = useMemo(()=>{
    return groupRole === 'staff' && groupHostCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  },[groupRole,groupHostCreateSessionSetting]);

  return (
    <UiTable>
      <UiActionBox>
        <UiTitle>課程</UiTitle>
        {(operatorAuth || isStaffCanCreateSession) && <Button icon='add' onClick={() => addTeacherToClass()}>新增課程</Button>}
      </UiActionBox>
      <UiActionBox>
        <UiflexBox>
          <IconInput
            placeholder='搜尋課程名稱'
            onChange={value => onchangeHandler(value, 'name')}
          />
          {
            dateInterval === NOW &&
            <>
              <TimePicker
                label="選擇課程開始時間"
                value={startAt}
                onChange={value => onchangeHandler(value, 'startAt')}
              />
              <TimePicker
                label="選擇課程結束時間"
                value={endAt}
                onChange={value => onchangeHandler(value, 'endAt')}
              />
            </>
          }

        </UiflexBox>
        <UiflexBox>
          <Select
            options={selectOptions}
            submitHandler={value => onSelectChangeHandler(value)}
            label="課程區間"
            value={ALL}
          />
        </UiflexBox>
      </UiActionBox>
      {(sessions.data.length === 0 && isListLoading === false) && <Prompt message="目前暫無課程"></Prompt>}
      {isListLoading && <Loading />}
      {
        sessions.data.length > 0 &&
        <Table
          data={sessions.data}
          schema={schema}
          changePage_Rows={changePage_Rows}
          totalPage={sessions.total}
          ActionComponents={ActionComponents}
          nowPage={nowPage}
        />
      }
    </UiTable>
  );
};
