import React, { useEffect } from 'react';
import { Link,useParams } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { AvatarMenu , Icon, NotificationList } from 'components';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useClass } from 'store/class';
import { 
  UiHeader,
  UiHeaderText,
  UiClassName,
  UiNotice,
  UiNoticeNew,
  UiIsLogin,
} from './Header.style';

/**
 * Header
 */

// eslint-disable-next-line react/prop-types
export const Header = ({ isNeedLogo = true }) => {
  const [{
    isOpen,
    isNew,
  },setState] = useSetState({
    isOpen:false,
    isNew:false,
  });
  const invModalToggle = () => {
    setState({
      isOpen:!isOpen
    });
  };

  const { organizationId, classId } = useParams();
  const [{ profile, myOrganization },{ fetchOrganizationById }] = useUser();
  const [{ myClasses }, { getGroupInfoById }] = useClass();
  const { dataInfo: myClassInfo } = myClasses;

  const isLogin = profile?.id;
  const customOrganizationLogoUrl = myOrganization?.organization?.customOrganizationLogoUrl;

  const getNewState = (isNew) => {
    setState({
      isNew,
    });
  };

  useEffect(()=>{
    if(!classId) return;
    getGroupInfoById(classId);
  },[classId]);

  useEffect(()=>{
    if(!organizationId) return;
    fetchOrganizationById(organizationId);
  },[organizationId]);

  return (
    <UiHeader isNeedLogo={isNeedLogo}>
      <div className="container">
        {
          isNeedLogo && <div className="logo">
            <Link to="/home">
              <img src={customOrganizationLogoUrl || logo} alt="全國最大線上家教中心"/>
            </Link>
          </div>
        }
        {
          isLogin && <Breadcrumbs className="breadcrumbs">
            {
              organizationId && <div className="breadcrumbItem">
                <UiHeaderText>
                  <Icon.Svg name="School"/>
                  {myOrganization?.organization?.officialName || ''}
                </UiHeaderText>
              </div>
            }
            {
              myClassInfo?.name &&
              <Typography color="textPrimary">
                <UiHeaderText>
                  <UiClassName>{myClassInfo?.name}</UiClassName>
                </UiHeaderText>
              </Typography>
            }
          </Breadcrumbs>
        }
        <UiIsLogin>
          {
            isLogin && <>
              <UiNotice onClick={invModalToggle}>
                <Icon.Svg name="Notice" />
                {isNew && <UiNoticeNew></UiNoticeNew>}
              </UiNotice>
            </>
          }
          <AvatarMenu />
        </UiIsLogin>
      </div>
      {isLogin && <NotificationList onClick={invModalToggle} isOpen={isOpen} getNewState={getNewState}/>}
    </UiHeader >
  );
};
