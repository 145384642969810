import React, { useEffect } from 'react';
import {
  UiSecretDiscuss,
  UiTitle,
  UiButtonBox,
  UiContent,
  UiCardBox,
} from './SecretDiscuss.style';
import {
  Button,
  PostModal,
  Loading,
  PostCard,
} from 'components';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { usePosts } from 'store/posts';
import { useParams, useHistory } from 'react-router-dom';
import defaultAvatarImage from 'assets/images/Avatar_Member_Default.png';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useCalTime } from 'utils/hooks/useCalTime';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';

/**
 * 導師商談
 */

export const SecretDiscuss = () => {
  const [{ myClasses }] = useClass();
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const [{ myOrganization, profile: { id, nickname, thumbnailUrl }}] = useUser();
  const [calTime] = useCalTime();
  const [{ postsList },
    { getPostList,
      createPost,
      updatePost,
      deletePost,
      replyPost,
      updateReplyPost,
      getPostReplies,
    }] = usePosts();
  const [{
    modalState,
    isAdmin,
    userType,
    userId,
    userNickname,
    userAvatar,
    isLoading,
    nowPage,
    rowsPage,
    listLoading,
    PostsDataList,
    hasNextPage,
    isCounseling,
    editModalState,
    readTime,
  }, setState] = useSetState({
    modalState: false,
    userType: '',
    userId: '',
    userNickname: '',
    userAvatar: '',
    isLoading: false,
    nowPage: 0,
    rowsPage: 5,
    listLoading: false,
    PostsDataList: [],
    hasNextPage: true,
    isCounseling: true,//是不是私密商談文章
    editModalState: false,
    readTime: 0,
  });

  //滾動加載觸發後事件
  const handleLoadMore = async () => {
    setState({
      listLoading: true
    });
    const page = await getPostList({ nowPage, rowsPage, isCounseling });
    const pageLength = page.posts && page.posts.length;
    let isHasNextPage = ((nowPage * rowsPage) + pageLength) >= page.total ? false : true;

    setState({
      PostsDataList:
        PostsDataList.length > 0 ?
          PostsDataList.concat(postsList.data) :
          postsList.data,
      listLoading: false,
      nowPage: nowPage + 1,
      hasNextPage: isHasNextPage
    });
  };

  //滾動加載trigger
  const infiniteRef = useInfiniteScroll({
    loading: listLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  });



  /**
   * 權限取得
   */
  useEffect(() => {
    const { dataInfo: { isOwner, userType, isHidden } } = myClasses;
    isHidden &&
      history.push(
        organizationId ?
          `/home/${organizationId}/class/${classId}/info` :
          `/home/class/${classId}/info`,
      );


    if (organizationId && myOrganization.isLoaded) {
      let targetThumbnailUrl ='';
      const {
        userProfile: { nickname: OrgNickname, thumbnailUrl: OrgThumbnailUrl,role },
        organization:{ staffProfileOriginSetting, customerProfileOriginSetting } } = myOrganization;
      if(role === 'staff'){
        targetThumbnailUrl =
          staffProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT ? thumbnailUrl : OrgThumbnailUrl;
      }else{
        targetThumbnailUrl =
          customerProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT ? thumbnailUrl : OrgThumbnailUrl;
      }
      setState({
        isAdmin: isOwner,
        userType,
        userId: id,
        userNickname: OrgNickname,
        userAvatar: targetThumbnailUrl,
      });
    } else {
      setState({
        isAdmin: isOwner,
        userType,
        userId: id,
        userNickname: nickname,
        userAvatar: thumbnailUrl,
      });
    }
  }, [myOrganization, myClasses]);
  //新增貼文彈窗
  const openModal = () => {
    setState({
      modalState: true
    });
  };
  const closeModal = () => {
    setState({
      modalState: false,
      editModalState: false,
    });
  };
  //編輯貼文彈窗
  const getPostModalState = state => {
    setState({
      modalState: state
    });
  };

  const onSubmitHandler = async createData => {
    setState({
      isLoading: true
    });

    const { content } = createData;
    const payload = {
      content,
      isCounseling
    };
    const isSuccess = await createPost(payload);
    if (isSuccess) {
      // reloadPostList();
      closeModal();
    }
  };
  //刪除
  const onDeleteHandler = async data => {
    let type = '';
    if (userId === data.ownerId) {
      type = 'delete';
    } else if (userType === 'teacher') {
      type = 'ban';
    }
    await deletePost({ type, ...data });
  };

  const onEditHandler = async editData => {
    const { postId, content } = editData;


    const payload = {
      content,
      isCounseling,
    };
    const isSuccess = await updatePost(postId)(payload);
    if (isSuccess) {
      // reloadPostList();
      closeModal();
    }
    return isSuccess;

  };

  //回覆貼文func
  const replieMessage = async (params, id) => {
    const { content } = params;
    const payload = {
      content
    };
    await replyPost(id)(payload);
  };

  const editReplieMessage = async (params, id) => {
    const { content, existFiles, files } = params;
    const payload = {
      content
    };
    if (existFiles.length === 0 && files.length === 0) {
      payload.attachments = [];
    }

    await updateReplyPost(id)(payload);
  };

  //取得先前留言
  const getPrevMessage = async (params, id) => {
    await getPostReplies(id)(params);
  };

  const getEditModalState = state => {
    setState({
      editModalState: state
    });
  };
  return (
    <UiSecretDiscuss ref={infiniteRef}>
      <UiTitle>導師商談室</UiTitle>
      {
        !isAdmin && userType === 'student' &&
        <UiButtonBox>
          <Button
            onClick={() => { openModal(); }}
          >
            開始聊聊
          </Button>
        </UiButtonBox>
      }
      {(postsList.data.length <= 0 && !listLoading) &&
        <UiContent>
          {
            isAdmin ?
              <div>讓學生與您分享他的私人問題</div> :
              userType === 'student' ?
                <div>私人提問，與導師說說你的問題</div> :
                <div>導師商談室功能目前限定導師與學生使用，請選擇其他功能!</div>
          }
        </UiContent>
      }

      <UiCardBox>
        {postsList.data && postsList.data.map(item => {
          const time = (item.publishedAt) && calTime(item.publishedAt);
          const haveReplyNotRead =
            ((item.replies && isAdmin) &&
              item.replies.posts.filter(reply => (reply.ownerId !== userId && reply.publishedAt > readTime))) || [];
          const isRead = (item.publishedAt) && (item.publishedAt < readTime && isAdmin && haveReplyNotRead.length <= 0);
          const canDelete = (userId === item.ownerId || isAdmin) ? true : false;
          const canEdit = (userId === item.ownerId) ? true : false;
          if (!item.isBanned)
            return (
              <PostCard
                key={item.id}
                data={item}
                isSecret={true}
                onDelete={onDeleteHandler}
                onEdit={onEditHandler}
                canDelete={canDelete}
                canEdit={canEdit}
                time={time}
                isAdmin={isAdmin}
                replieMessage={replieMessage}
                editReplieMessage={editReplieMessage}
                getPrevMessage={getPrevMessage}
                avatarSrc={userAvatar || defaultAvatarImage}
                editModalState={editModalState}
                getEditModalState={getEditModalState}
                isRead={isRead}
              />
            );
        })}
      </UiCardBox>
      {listLoading && <Loading />}

      <PostModal
        isOpen={modalState}
        isSecret={true}
        getModalState={getPostModalState}
        onSubmit={onSubmitHandler}
        title={'導師商談'}
        avatarSrc={userAvatar || defaultAvatarImage}
        name={userNickname}
        dialogContent={<span>你尚未分享貼文<br/>確定要離開頁面且不發佈貼文</span>}
      />
    </UiSecretDiscuss>
  );
};

SecretDiscuss.propTypes = {

};


