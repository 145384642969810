import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { paginationFilter } from 'utils/pagination';
import { getStudentAllVideoRecord } from 'services/api/home/video';
import { useUser } from 'store/user';
import { Table,Button } from 'components';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { UiHomeVideoHistoryTable } from './HomeVideoHistoryTable.style';


/**
 * 查看學生觀看紀錄
 */

export const HomeVideoHistoryTable = () => {
  const isVisible = usePageVisibility();
  const history = useHistory();
  const [{ profile }] = useUser();
  const { organizationId, classId, userId } = useParams();
  const [
    {
      data,
      nowPage,
      rowsPage,
    }, setState] = useSetState({
      data: [],
      nowPage: 0,
      rowsPage: 100,
    });

    const changePage_Rows = params => {
      const { newPage, newRowsPage } = params;
      setState({
        nowPage: newPage,
        rowsPage: newRowsPage
      });
    };

    const schema = {
      item: {
        name: '品項',
        defaultValue: '-'
      },
      name:{
        name:'影片名稱',
        defaultValue:'--'
      },
      time:{
        name: '時間',
        defaultValue:'--'
      }
    };

    const fetchStudentVideoRecord = async () => {
      const res = await getStudentAllVideoRecord({ classId, studentId: userId });
      if(res.isSuccess){
        const nextData = res.data.sort((a,b)=> b.addAt - a.addAt);
        setState({ data: nextData });
      }
    };

    useEffect(() => {
      fetchStudentVideoRecord();
    },[isVisible]);

  return (
    <UiHomeVideoHistoryTable>
      <div className="flex">
        <h4>觀看紀錄列表</h4>
        <Button
          buttonColor="new"
          onClick={()=>history.push(`/home/${organizationId}/class/${classId}/video?tab=0`)}>
            返回上一頁
        </Button>
      </div>
      <Table
        data={paginationFilter(data,nowPage,rowsPage)}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={data.length}
        nowPage={nowPage}
      />
    </UiHomeVideoHistoryTable>
  );
};
