import React from 'react';
import { Table } from 'components';
import { convertArrayToMapById } from 'utils/array';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import {
  UiSessionPerformance,
  UiPerformanceTitle,
  UiPerformanceForUser,
  UiPerformanceCard,
  UiPerformanceCardTitle,
  UiPerformanceCardIcon,
  UiPerformanceCardContent,
  UiPerformanceCardAverage,
} from './SessionPerformance.style';
import { useUser } from 'store/user';
import { useClass } from 'store/class';


/**
 * 上課表現
 */

 const schema = {
  attendeeNumber:{
    name: '座號',
    defaultValue: '-',
  },
  nickname:{
    name: '姓名',
    defaultValue: '-',
  },
  trophy:{
    name: '總獎盃數',
    defaultValue: '-',
  },
  raiseHand:{
    name: '舉手次數',
    defaultValue: '-',
  },
  speakerSecond:{
    name: '發言秒數',
    defaultValue: '',
  },
  inattentive:{
    name: '不專心次數',
    defaultValue: '-',
  },
};

export const SessionPerformance = ({
  data,
}) => {
  const [{ myClasses }] = useClass();
  const [{ profile }] = useUser();
  const { dataInfo } = myClasses;
  // 班級身份判斷
  const isUser = dataInfo?.users?.some(user => user === profile.id);

  const { userRecords, users, userRecordAverages } = data;
  const sessionPeriodFormat = (time, status) => {
    if (status === 'attended' || !status) {
      if (time === 0) return '0';
      const minSec = time/1000;
      const min = Math.floor(minSec/60);
      const sec = time%60;
      return `${min}:${sec}`;
    } else {
      return '-';
    }
  };

  const statusFormat = (value, status) => {
    if (status === 'attended' || !status) {
      if (value === 0) return '0';
      return value;
    } else {
      return '-';
    }
  };

  const userRecordsLength = userRecords.length;
  const usersMap = convertArrayToMapById(users);
  userRecords.push(userRecordAverages);
  let nextUserRecord = userRecords.map((record, index) => {
    const { userId, trophy, inattentive, raiseHand, speakerSecond, status, } = record;
    const speakerSecondTime = sessionPeriodFormat(speakerSecond, status);
    return {
      userId,
      attendeeNumber: index === userRecordsLength ? ' ' : usersMap[userId]?.attendeeNumber,
      nickname: index === userRecordsLength ? '班級平均' : usersMap[userId]?.nickname,
      trophy: statusFormat(trophy, status),
      raiseHand: statusFormat(raiseHand, status),
      speakerSecond: speakerSecondTime,
      inattentive: statusFormat(inattentive, status),
    };
  });

  return (
    <UiSessionPerformance>
      <UiPerformanceTitle>
        {
          !isUser && '學生課堂表現'
        }
        {
          isUser && '課堂表現'
        }
      </UiPerformanceTitle>
      {
       !isUser && <Table
          data={nextUserRecord || []}
          schema={schema}
          totalPage={userRecords.length}
          isNeedPagination={false}
        />
      }
      {
        isUser && <UiPerformanceForUser>
          <UiPerformanceCard>
            <UiPerformanceCardTitle>
              <span>獎盃數</span>
              <UiPerformanceCardIcon className="trophy">
                <Icon.Svg name="Trophy" />
              </UiPerformanceCardIcon>
            </UiPerformanceCardTitle>
            <UiPerformanceCardContent>{userRecords[0].trophy}</UiPerformanceCardContent>
            <UiPerformanceCardAverage>班級平均值 {userRecordAverages.trophy}</UiPerformanceCardAverage>
          </UiPerformanceCard>
          <UiPerformanceCard>
            <UiPerformanceCardTitle>
              <span>不專心次數</span> 
              <UiPerformanceCardIcon className="running">
                <Icon.Svg name="Running" />
              </UiPerformanceCardIcon>
            </UiPerformanceCardTitle>
            <UiPerformanceCardContent>{userRecords[0].inattentive}</UiPerformanceCardContent>
            <UiPerformanceCardAverage>班級平均值 {userRecordAverages.inattentive}</UiPerformanceCardAverage>
          </UiPerformanceCard>
          <UiPerformanceCard>
            <UiPerformanceCardTitle>
              <span>舉手次數</span> 
              <UiPerformanceCardIcon className="raisehand">
                <Icon.Svg name="Raisehand" />
              </UiPerformanceCardIcon>
            </UiPerformanceCardTitle>
            <UiPerformanceCardContent>{userRecords[0].raiseHand}</UiPerformanceCardContent>
            <UiPerformanceCardAverage>班級平均值 {userRecordAverages.raiseHand}</UiPerformanceCardAverage>
          </UiPerformanceCard>
          <UiPerformanceCard>
            <UiPerformanceCardTitle>
              <span>發言時間</span>
              <UiPerformanceCardIcon className="messageWaveform">
                <Icon.Svg name="MessageWaveform" />
              </UiPerformanceCardIcon>
            </UiPerformanceCardTitle>
            <UiPerformanceCardContent>
              {sessionPeriodFormat(userRecords[0].speakerSecond)}
            </UiPerformanceCardContent>
            <UiPerformanceCardAverage>
              班級平均值
              {sessionPeriodFormat(userRecordAverages.speakerSecond)}
            </UiPerformanceCardAverage>
          </UiPerformanceCard>
        </UiPerformanceForUser>
      }
    </UiSessionPerformance>
  );
};

SessionPerformance.propTypes = {
  data: PropTypes.array,
};
