import { Api } from 'services/api';
import { stringify } from 'query-string';

export const loginToOnePaper = async () => {
  return Api.get(`${process.env.REACT_APP_ONEPAPER_DOMAIN}/api/Member/Login`);
};

export const getPaperList = async (params) => {
  const { year, paperToken } = params;
  const queryParams = {
    year
  };
  const querystring = stringify(queryParams);
  return fetch(`${process.env.REACT_APP_ONEPAPER_DOMAIN}/api/Exam/Query?${querystring}`,{
    headers: {
      authorization:paperToken
    }
  }).then(res=>res.json());
};
