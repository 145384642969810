import styled from 'styled-components';

export const UiSessionRecord = styled.div`
	display: flex;
	overflow-x: auto;
	padding: 20px 0;
	width: 100%;
`;

export const UiTimeLineContainer = styled.div`
	display: block;

	&.user {
		width: 100%;
	}
`;

export const UiTimeLineTRow = styled.div`
	display: flex;
	align-items: center;

	&.user {
		width: 100%;

		> div {
			border-left: initial;
		}

		> div:first-child {
			border-top-left-radius: 30px;
		}

		> div:last-child {
			border-top-right-radius: 30px;
		}
	}
`;

export const UiTimeLineRow = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(224, 224, 224, 1);

	&.user {
		border-bottom: 0;

		> div {
			border-left: initial;
			padding: 80px  8px;
		}

		> div:first-child {
			border-bottom-left-radius: 30px;
		}

		> div:nth-child(3) {
			border-bottom-right-radius: 30px;
		}

		> div:last-child {
			padding: 0;
		}
	}
`;

export const UiTimeLineBackground = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	width: 100%;
	height: 8px;
	background-color: #fbe4e0;
	border-radius: 4px;
	z-index: 1;
`;

export const UiTimeLine = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: ${({left}) => `${left}px` };
	width: ${({right, left}) =>`${right - left}px`};
	height: 8px;
	background-color: #ec7963;
	z-index: 2;
`;

export const UiTimeLineBlock = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 17px 8px;
	width: ${({width}) => width || '100px'};
	height: 53.6px;
	font-size: 14px;
	color: ${({color}) => color || '#121232'};
	background-color: ${({bgc}) => bgc || '#fafafc'};
	line-height: 22px;

	& + div {
		border-left: 1px solid #f9c74f;
	}
`;

export const UiTimeText = styled.div`
	position: absolute;
	top: -30px;
	left: ${({position}) => position};
	font-size: 14px;
	font-weight: 600;
	color: #242c3f;
	line-height: 30px;
`;

export const UiRecordTitle = styled.div`
	margin: 66px 0 48px;
	font-size: 25px;
	font-weight: 700;
`;

export const UiRecordUser = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 15px;
	width: 100%;
`;
export const UiRecordUserText = styled.div`
	margin-right: 20px;
`;
export const UiRecordUserIcon = styled.div`
	margin-right: 10px;
	width: 16px;
	height: 16px;
	background-color: #ec7963;
	border-radius: 50%;
`;