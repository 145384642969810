import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Table, IconInput, Select, Button, ButtonSelect, Modal, TransferList } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';
import { UiTable, UiActionBox, UiflexBox, UiTableButtonBox } from 'styles';
import { useTeacher } from 'store/teacher';
import { useClass } from 'store/class';
import { 
  getOrganizationStaffForGroupOwner as getOrganizationStaffForGroupOwnerApi,
  addGroupHostsFromOrganizationStaffs as addGroupHostsFromOrganizationStaffsApi,
  removeGroupHost as removeGroupHostApi,
} from 'services/api/organization/teacher';

/**
 * 教師列表Table
 */
const schema = {
  nickname: {
    name: '暱稱',
    defaultValue: '無'
  }
};


const selectOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];
export const HomeTeacherTable = () => {
  const { organizationId, classId } = useParams();
  const { setAlert } = useAlert();
  const [{ teachers }, { getTeachers }] = useTeacher();
  const [{ myClasses }] = useClass();
  const ownerId = myClasses.dataInfo.ownerId;
  const history = useHistory();
  const [{
    nowPage,
    rowsPage,
    searchType,
    searchValue,
    nickname,
    mobileNumber,
    isLoading,
    isOpenModal,
    organizationsTeacherData,
    organizationsTeacherTotal,
    organizationsNowPage,
    organizationsRowsPage,
    teacherList,
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    nickname: '',
    mobileNumber: '',
    searchType: '',
    searchValue: '',
    isLoading: false,
    isOpenModal: false,
    organizationsTeacherData: [],
    organizationsTeacherTotal: 0,
    organizationsNowPage: 0,
    organizationsRowsPage: 10,
    teacherList: [],
  });

  // table 操作
  const ActionComponents = ({ params }) => {
    return (
      <UiTableButtonBox>
        { ownerId !== params.id && <Button
            onClick={() => { doRemoveGroupTeacher(params); }}
            isLoading={isLoading}
            buttonColor="new"
          >
            移出班級
          </Button>
        }
      </UiTableButtonBox>
    );
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'searchValue') {
      if (searchType === '') return;
      setState({
        nickname: '',
        mobileNumber: '',
        [searchType]: value,
        nowPage: 0
      });
    }
    setState({
      [key]: value,
    });
  };

  const getGroupOperationAuth = () => {
    return myClasses.dataInfo.isOwner || false;
  };

  const doRemoveGroupTeacher = async (params) => {
    const { id } = params;
    const { isSuccess } = await removeGroupHostApi(classId, id);
    if(isSuccess){
      setAlert('刪除教師成功!', 'success');
      reloadTeacherList();
    }else{
      setAlert('刪除教師失敗!', 'error');
    }
  };

  const reloadTeacherList = () => {
    setState({
      nowPage: 0
    });
    getTeachers({ nowPage, rowsPage });
  };

  // 校內教師列表
  const fetchGetOrganizationStaffForGroupOwner = async () => {
    const params = {
      nowPage:organizationsNowPage,
      rowsPage:organizationsRowsPage,
      excludeCurrentGroupHost: true,
    };
    const { data, isSuccess} = await getOrganizationStaffForGroupOwnerApi(organizationId, classId, params);
    if(isSuccess){
      const { userProfiles, total } = data;
      const nextOrganizationsTeacherData = userProfiles.map(item => {
        return {
          ...item,
          id: item.id,
          imagePath: item.thumbnailUrl,
          title: item.nickname,
          content: item.mobileNumber,
        };
      });

      const newOrganizationsTeacherData = organizationsTeacherData.concat(nextOrganizationsTeacherData);
      setState({
        organizationsTeacherData: newOrganizationsTeacherData,
        organizationsTeacherTotal: total,
        organizationsNowPage: organizationsNowPage + 1,
      })
    }else{
    //   setAlert('取得列表失敗!', 'error');
    }
  }

  // 校內加入教師
  const fetchGroupHostsFromOrganizationStaffsApi = async teacherIds => {
    const teacherIdArr = teacherIds.map(item => item.id );
    const { isSuccess } = await addGroupHostsFromOrganizationStaffsApi(classId, { hostIdList: teacherIdArr })
    if(isSuccess){
      setAlert('加入班級成功!', 'success');
      reloadTeacherList();
    }else{
      setAlert('加入班級失敗!', 'error');
    }
  }

  useEffect( () => {
    // 第一次
    fetchGetOrganizationStaffForGroupOwner();
  },[])


  useEffect(() => {
    getTeachers({ nowPage, rowsPage, nickname, mobileNumber });
  }, [nowPage, rowsPage, nickname, mobileNumber]);
  const goInvitePage = () => history.push('member/invite');
  const buttonSelectOption = [
    {
      label: '從校內選擇',
      onClick: () => { setState({isOpenModal: true}) },
    },
    {
      label: '從校外邀請',
      onClick: goInvitePage,
    }
  ]

  const getValueHandler = value => {
    fetchGroupHostsFromOrganizationStaffsApi(value);
    closeHandler();
  };

  const closeHandler = () => {
    setState({
      isOpenModal: false
    });
  };

  const radioChangeHandler = value => {
    if (!value) return;
  };

  const getModalStateHandler = state => {
    setState({
      isOpenModal: state
    });
  };

  const onScrollToApi = isCallApi => {
    if(!isCallApi) return;
    fetchGetOrganizationStaffForGroupOwner();
  }

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Select label="搜尋條件" options={selectOptions} submitHandler={value => onSubmitHandler(value, 'searchType')} />
          <IconInput
            placeholder='搜尋條件'
            value={searchValue}
            onChange={value => onSubmitHandler(value, 'searchValue')}
          />
        </UiflexBox>
        {
          getGroupOperationAuth() && <ButtonSelect option={buttonSelectOption}>邀請教師</ButtonSelect>
        }
      </UiActionBox>
      <Table
        data={teachers.data}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={teachers.total}
        nowPage={nowPage}
        ActionComponents={getGroupOperationAuth() && ActionComponents}
      />
      <Modal
        isIconShow={false}
        isOpen={isOpenModal}
        title="選擇教師"
        content={
          <TransferList
            data={organizationsTeacherData}
            dataTotal={organizationsTeacherTotal}
            getValue={getValueHandler}
            closeHanlder={closeHandler}
            onScrollToApi={onScrollToApi}
            onRadioChange={radioChangeHandler}
          />
        }
        getModalState={getModalStateHandler}
      />
    </UiTable>);
};
