import styled from 'styled-components';

export const UiSessionReport = styled.div`
display: block;
padding: 48px 32px;
`;

export const UiSessionEmpty = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const UiEmptyImg = styled.div`
	width: 29%;
	height: 207px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
	margin-bottom: 25px;
`;