import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Header,SessionGroup } from 'components';
import logo from 'assets/images/logo.png';
import oneask from 'assets/images/ask.png';
import { UiSingleLayout } from './SingleLayout.style';


/**
 * 沒有側邊欄的版型
 */

 const GREETINGS = {
  MORNING: '早安',
  AFTERNOON: '午安',
  NIGHT: '晚安'
};

 const getTimeState = () => {
  const nowHour = new Date().getHours();
  if (nowHour >= 0 && nowHour <= 12) {
    return GREETINGS.MORNING;
  } else if (nowHour > 12 && nowHour <= 18) {
    return GREETINGS.AFTERNOON;
  } else if (nowHour > 18 && nowHour <= 24) {
    return GREETINGS.NIGHT;
  }
};

export const SingleLayout = ({ children }) => {
  return (
    <UiSingleLayout>
       <div className="leftSidebar">
        <div className="logo">
          <Link to="/home">
            <img src={logo} alt="全國最大線上家教中心"/>
          </Link>
        </div>
       <div className="greetings">{getTimeState()} !</div>
        <div className="main">
          <div className="subTitle">
            <span>今日課程</span>
            <Link className="readMore" to="/sessions">查看課程</Link>
          </div>
          <SessionGroup/>
          {/* <a className="wtfIssue" href="https://oneask.oneclass.com.tw/index.html" target="_blank" rel="noreferrer">
            <img src={oneask} alt="oneAsk" />
          </a> */}
        </div>
      </div>
      <Header className="header" isNeedLogo={false}/>
      <div className="main">
        {children}
      </div>
    </UiSingleLayout>
  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
};


