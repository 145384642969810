import React,{ useEffect,useState,useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { loginToOnePaper } from 'services/api/onePaper';
import { useUser } from 'store/user';
import { HomeGroupLayout, HomeOnlineExam } from 'components';


/**
 * 線上測驗頁面
 */

export const HomeOnlineExamPage = () => {
  const [token,setToken] = useState(null);
  const [{ myOrganization }] = useUser();
  const history = useHistory();
  const isCanUseOnlineExam = myOrganization.organization?.onlineExamSetting === 'enabled';
  
  const fetchOnePaperToken = async () => {
    const { data } = await loginToOnePaper();
    if(data){
      const { token } = data;
      setToken(token);
    }
  };
  const isTeacher = useMemo(()=>{
    if(!myOrganization.isLoaded) return false;
    switch(myOrganization?.userProfile?.role){
      case 'groupOwner':
      case 'staff':
        return true;
      case 'student':
        return false;
      default:
        return false;
    }
  },[myOrganization.isLoaded]);

  useEffect(()=>{
    fetchOnePaperToken();
  },[]);

  // TODO: 目前只要貼網址就倒回首頁
  useEffect(() => {
    if (!isCanUseOnlineExam) {
      history.push('/');
    }
  }, [isCanUseOnlineExam]);

  return (
    <HomeGroupLayout>
      { (token || !isTeacher) && <HomeOnlineExam token={token} isTeacher={isTeacher}/>}
    </HomeGroupLayout>
  );
};
