import React,{ useEffect, useMemo } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import { useParams } from 'react-router-dom';
import { useAlert } from 'utils/hooks/useAlert';
import { UiActionBox, UiflexBox } from 'styles';
import { Icon, Table, Select, BsModal, HomeOnlineExamTableV2 } from 'components';
import { getGroupInfoById } from 'services/api/home/class';
import { getPaperList } from 'services/api/onePaper';
import ExamModal from './ExamModal';
import { useUser } from 'store/user';
import { createOrganizationGroupExam } from 'services/api/home/onlineExam';
import {
  Tab,
  TextField as MatTextField
 } from '@material-ui/core';
import {
  UiHomeOnlineExam,
  UiTitle,
  UiMatTabs,
  UiExamIcons,
} from './HomeOnlineExam.style';


/**
 * 線上測驗頁面for Teacher
 */

 const schema = {
  year: {
    name: '學年度',
    defaultValue: '',
  },
  eduSubjectName:{
    name:'學制科目',
    defaultValue: '',
  },
  name: {
    name: '試卷名稱',
    defaultValue: '',
  },
  // groupUsageCount: {
  //   name: '已派卷次數',
  //   defaultValue: ''
  // },
};

export const HomeOnlineExam = ({ token,isTeacher }) => {
  const [{
    tabIndex,
    subjectKey,
    searchValue,
    nowPage,
    rowsPage,
    classUsers,
    isPromptModal,
    subjectPlaceholder,
    placeholder,
    examPaper,
    eduSubjectMap,
    examPaperTotal,
    isOpenModal,
    paperId,
    examName,
    examPeriod,
    startAt,
    endAt,
    isLoading,
  },setState] = useSetState({
    tabIndex: 0,
    subjectKey:'',
    searchValue:'',
    nowPage: 0,
    rowsPage: 10,
    classUsers: [],
    isPromptModal: false,
    subjectPlaceholder:'全部科目',
    placeholder: '搜尋試卷名稱',
    examPaper: [],
    eduSubjectMap : [],
    examPaperTotal: 0,
    isOpenModal: false,
    paperId: '',
    examName:'',
    examPeriod: null,
    startAt: null,
    endAt: null,
    isLoading: false
  });
  const { classId, organizationId } = useParams();
  const { setAlert } = useAlert();
  const [{ profile }] = useUser();

  const getTabIndexHandler = (e,tabIndex) => {
    setState({
      tabIndex
    });
  };

  const fetchOnePaperInfo = async () => {
    if(!token) return;
    const year = 111;
    const params = {
      paperToken: token,
      year
    };
    const { data } = await getPaperList(params);
    const { examPaper, eduSubjectMap } = data;
    const nextEduSubject = [{
      name:'全部科目',
      value:''
    }].concat(eduSubjectMap.map(eduSubject=> ({
      name: eduSubject.name,
      value: eduSubject.code
    })));

    setState({
      examPaper,
      eduSubjectMap: nextEduSubject,
      examPaperTotal: examPaper.length
    });
  };

  const examPaperList = useMemo(()=>{
    return examPaper.map(paper=> ({
      ...paper,
      year: paper.attributes.year,
      code: paper.attributes.education + paper.attributes.subject,
    })).filter(paper=> subjectKey === '' || subjectKey === paper.code)
    .filter(paper=> searchValue === '' || paper.name.includes(searchValue))
    .slice(nowPage * rowsPage, nowPage * rowsPage + rowsPage);
  },[examPaper,nowPage,rowsPage,subjectKey,searchValue]);

  // 換頁
  const changePage_Rows = params => {
    // newPage 第幾頁
    // newRowsPage 一頁幾筆
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  // 沒有學生的彈窗控制
  const onPrompt = () => {
    setState({
      isPromptModal:!isPromptModal
    });
  };

  // 科目 select
  const onSubjectSelectHandler = value => {
    setState({
      subjectKey: value,
    });
  };

  const examModalToggle = () => {
    setState({
      isOpenModal: !isOpenModal
    });
  };

   // 操作
   const ActionComponents = ({ params }) => {
    const { uid:paperId,name } = params;
    const isCantCreate = classUsers?.length > 0;
    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${paperId}`;
      window.open(url);
    };

    const onCreateExam = () => {
      setState({
        paperId,
        examName: name,
        isOpenModal: true,
      });
    };

    return (
      <UiExamIcons>
        <Icon.Svg
          name="Visibility"
          onClick={onViewSession}
        />
        <Icon.Svg
          name="Plane"
          onClick={isCantCreate ? onCreateExam : onPrompt}
          color="#ec7963"
        />
      </UiExamIcons>
    );
  };

  // input change
  const onSearchChange = event => {
    setState({
      searchValue: event.target.value,
    });
  };


  const fetchClasses = async () => {
    const { isSuccess,data } = await getGroupInfoById(classId);
    if(isSuccess) {
      setState({
        classUsers: data.users,
      });
    }else{
      setAlert('取得學生列表失敗', 'error');
      setState({
        classUsers: [],
      });
    }
  };

  const onModalChange = (value,key) => {
    setState({
      [key]:value
    });
  };

  const createExam = async (type) => {
    const payload = {
      paperId,
      organizationId,
      groupId: classId,
      period: parseInt(examPeriod),
      name: examName,
      markers: [profile.id],
    };

    if(type === 'reserve'){
      payload.startAt = startAt;
      payload.endAt = endAt;
    }
    if(!examPeriod || !examName) return;
    if(type === 'reserve' && (!startAt || !endAt)) return;
    setState({
      isLoading: true
    });
    const { isSuccess } = await createOrganizationGroupExam(payload);
    if(isSuccess){
      setAlert('派卷成功','success');
      setState({
        paperId: '',
        examPeriod: null,
        examName: '',
        startAt: '',
        endAt: '',
        isOpenModal: false
      });
    }else{
      setAlert('派卷失敗','error');
    }
    setState({
      isLoading: false
    });
  };

  // useEffect(() => {
  //   const educationKey = classes.dataInfo?.educationName;
  //   if(educationKey) {
  //     if(!subjectMap[educationKey]) {
  //       emptySubjectOption();
  //     } else {
  //       const subjectOption = subjectMap[educationKey].map(item=>({
  //         ...item,
  //         value: item.code,
  //       }));
  //       const nextSubject = subjectOption.length > 0 ? [
  //         {
  //           code:'all',
  //           value:'all',
  //           name:'全部科目'
  //         },
  //         ...subjectOption
  //       ] : [];
  //       setState({
  //         subjectOption : nextSubject,
  //         subjectKey: '',
  //         subjectPlaceholder: '全部科目'
  //       });
  //     }
  //   }
  //   setState({
  //     searchValue: '',
  //   });
  // }, [classes.dataInfo?.educationName, subjectMap]);

  useEffect(() => {
    fetchClasses();
    fetchOnePaperInfo();
    // fetchOrganizationGroupPaperOptions();
  }, []);

  useEffect(()=>{
    setState({
      tabIndex: isTeacher ? 0 : 1
    });
  },[isTeacher]);

  return (
    <UiHomeOnlineExam>
      <UiTitle>線上測驗</UiTitle>
      {isTeacher &&
      <UiMatTabs
        value={tabIndex}
        onChange={getTabIndexHandler}
      >
        <Tab label="試卷列表" />
        <Tab label="測驗列表" />
      </UiMatTabs>}
      {tabIndex === 0 &&
        <UiActionBox>
          <UiflexBox>
            <Select
              label={subjectPlaceholder}
              options={eduSubjectMap}
              submitHandler={onSubjectSelectHandler}
            />
            <MatTextField
              variant='outlined'
              value={searchValue}
              placeholder={placeholder}
              onChange={onSearchChange}
            />
          </UiflexBox>
        </UiActionBox>
      }
      {
        tabIndex === 0 ?
        <Table
          data={examPaperList}
          schema={schema}
          changePage_Rows={changePage_Rows}
          totalPage={examPaperTotal}
          ActionComponents={ActionComponents}
          nowPage={nowPage}
        /> : <HomeOnlineExamTableV2 />
      }
      <BsModal
        open={isPromptModal}
        title="警告"
        isFull={true}
        cancelDisplay={false}
        onOk={onPrompt}
        onCancel={onPrompt}
      >
        此班級內沒有學生
      </BsModal>
      <ExamModal
        isOpen={isOpenModal}
        onClick={createExam}
        onCancel={examModalToggle}
        isLoading={isLoading}
        onChange={onModalChange}
        examPeriod={examPeriod}
        startAt={startAt}
        endAt={endAt}
        examName={examName}
      />
    </UiHomeOnlineExam>
  );
};
