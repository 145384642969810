import styled from 'styled-components';

export const UiHomeGroupLayout = styled.div`
	display: flex;
	height: 100%;
	min-height: 100vh;
	background-color: #fff;
`;

export const UiHomeGroupContent = styled.div`
	padding: ${({ showSideBar }) => showSideBar ? '2rem 0 2rem' : '0 0 2rem'};
	padding-top: 0;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	flex: 1;

	/* ${({ theme: { media } }) => media.desktop`
      width:${({ theme }) => theme.mediaWidth.desktop}
      `}
	${({ theme: { media } }) => media.notebook`
      width:${({ theme }) => theme.mediaWidth.notebook}
      `}
	${({ theme: { media } }) => media.pad`
      width:${({ theme }) => theme.mediaWidth.pad}
      `}
	${({ theme: { media } }) => media.phone`
      width:${({ theme }) => theme.mediaWidth.phone}
      `} */

	  height: 100vh;
	  display: flex;
	  flex-direction: column;
`;

export const UiChildrenContainer = styled.div`
	overflow-y: auto;
	padding: 0 1rem;

	/* width: calc(100% - 200px); */

	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
